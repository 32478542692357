.button,
button,
input[type='submit'],
input[type='reset'],
input[type='button'],
.com-baforms-wrapper .ba-form-submit-btn,
.n2-ss-button-container a {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1rem 1.25rem;
  background: linear-gradient(120deg, $tertiary-colour, $primary-colour);
  background-size: 150% 100%;
  background-position: 80% 0;
  border: 2px solid transparent;
  color: $white;
  font-size: 0.8rem;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: normal;
  font-family: $primary-font;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  @include transition($global-transition);
  -webkit-appearance: none;

  &:hover {
    color: $white;
    background-position: 5% 0;
    border: 2px solid transparent;
  }
  &:active {
    border: 2px solid transparent;
  }
  &:visited {
    color: $white;
  }
}

.button {
  &--large {
    font-size: 0.9rem;
    padding: 1.25rem 1.75rem;
  }
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }

  &--primary {
    background: $primary-colour;
    &:hover {
      background: darken($primary-colour, 6%);
    }
  }

  &--secondary {
    background: $secondary-colour;
    &:hover {
      background: darken($secondary-colour, 6%);
    }
  }

  &--red {
    background: #d0342c;
    &:hover {
      background: darken(#d0342c, 6%);
    }
  }

  &--outline {
    color: $secondary-colour;
    background: transparent;
    border: 2px solid $secondary-colour;
    &:hover {
      color: $white;
      background: $primary-colour;
      border: 2px solid $primary-colour;
      &:visited {
        color: $white;
      }
    }
    &:active {
      border: 2px solid $secondary-colour;
    }
    &:visited {
      color: $secondary-colour;
    }
    &-white {
      color: $white !important;
      border-color: $white;
      &:hover {
        background: $primary-colour;
        border: 2px solid $primary-colour;
        &:visited {
          color: $white;
        }
      }
    }
    &-secondary {
      color: $tertiary-colour !important;
      border-color: $tertiary-colour;
      &:hover {
        color: $white !important;
        background: $tertiary-colour;
        border: 2px solid $tertiary-colour;
        &:visited {
          color: $white !important;
        }
      }
    }
  }
  &-wrap {
    &--center {
      text-align: center;
    }
  }
  &--margin {
    margin: 1rem;
  }

  &--underline {
    padding: 8px;
    background: transparent;
    border-bottom: 2px solid $white;
    border-radius: 0;
    &:hover {
      background: transparent;
      color: $primary-colour;
      border-bottom-color: $primary-colour;
    }

    &-2 {
      &:hover {
        color: $tertiary-colour;
        border-bottom-color: $tertiary-colour;
      }
    }
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.n2-ss-slider {
  .n2-ss-button-container {
    a,
    .button {
      display: inline-flex !important;
      flex-direction: column !important;
      padding: 1rem 1.5rem !important;
      background: linear-gradient(
        120deg,
        $tertiary-colour,
        $primary-colour
      ) !important;
      background-size: 150% 100% !important;
      background-position: 80% 0 !important;
      border: 2px solid transparent !important;
      color: $white !important;
      font-size: 0.8rem !important;
      font-weight: normal !important;
      text-decoration: none !important;
      text-transform: uppercase !important;
      line-height: normal !important;
      font-family: $primary-font !important;
      text-transform: uppercase !important;
      text-align: center !important;
      letter-spacing: 1.5px !important;
      outline: none !important;
      border-radius: 8px !important;
      transition: 500ms ease-in-out !important;
      &--underline {
        padding: 0.5rem 0.5rem !important;
        border-radius: 0 !important;
        background: transparent !important;
        border-bottom: 2px solid $white !important;
        &:hover {
          background: transparent !important;
          color: $primary-colour !important;
          border-bottom-color: $primary-colour !important;
        }

        &-2 {
          &:hover {
            color: $tertiary-colour !important;
            border-bottom-color: $tertiary-colour !important;
          }
        }
      }
    }
  }
}
