/* Footer
================================================== */
#sponsors-section {
  padding: 0;
  background-color: $white;
}
.sponsors {
  display: flex;
  align-items: stretch;
  .n2-section-smartslider {
    .n2-ss-section-main-content,
    .n2-ss-layer,
    .n2-ss-layer-col {
      padding: 0 !important;
    }
    .n2-ss-margin {
      margin: 0 !important;
    }
    img {
      width: 100% !important;
      max-width: 180px !important;
      max-height: 80px !important;
    }
  }
  .btn-logo--slider {
    box-shadow: none !important;
  }
  &__left {
    .wf-columns {
      flex-direction: column-reverse;
      gap: 0;
      height: 100%;
      @include bp(xlarge) {
        flex-direction: row;
      }
    }
    &-image {
      img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
        max-height: 400px;
        @include bp(large) {
          max-height: 100%;
        }
      }
    }
    &-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding: 4rem 2rem;
      background-color: $secondary-colour;
      * {
        color: $white;
      }
      .button {
        margin: 0;
      }
    }
  }
  &__right {
    padding: 3rem 0;
    p {
      text-transform: uppercase;
      font-size: 0.8rem;
      strong {
        font-weight: 400;
      }
    }
  }
}

footer {
  * {
    color: $white;
    @include bp_max(medium) {
      text-align: center;
    }
  }

  a,
  .nav a {
    &:visited {
      color: $white;
    }
    &:hover {
      color: $tertiary-colour;
    }
  }

  h3 {
    margin: 0 0 1.5rem;
    font-size: 1.4rem;
    font-family: $primary-font;
    text-transform: capitalize;
    letter-spacing: normal;
    font-weight: bold;
  }

  .nav {
    display: block;
    li {
      display: block;
      width: 100%;
      margin-bottom: 0.6em;
      text-align: left;
      @include bp_max(medium) {
        text-align: center;
      }
    }
    a {
      margin: 1rem 0;
      font-size: 0.9rem;
      color: $white;
      padding: 0;
      text-transform: none;
      font-weight: normal;
    }
  }

  .social {
    margin-bottom: 2em;
    li {
      //width: 48px;
      height: 48px;
      //margin: 8px;
    }
    a {
      * {
        fill: $white;
      }
      &:hover {
        * {
          fill: $tertiary-colour;
        }
      }
    }
  }
}

.footer {
  &__border {
    width: 100%;
    height: 10px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#168ab9+1,96d46d+100 */
    background: #168ab9; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #168ab9 1%,
      #96d46d 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      #168ab9 1%,
      #96d46d 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      #168ab9 1%,
      #96d46d 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#168ab9', endColorstr='#96d46d',GradientType=1 ); /* IE6-9 */
  }

  &__top {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    padding: 4em;
    background-color: $black;
    @include bp(large) {
      padding: 6em 0;
    }

    .columns {
      @include bp_max(medium) {
        margin-bottom: 3em;
      }
    }

    &--2 {
      margin-top: 3rem !important;
    }
  }

  &__logo {
    margin-bottom: 1rem;

    img {
      width: 300px;
    }
  }

  &__left {
    ul {
      margin: 0;
      list-style: none;
    }
    span {
      margin-left: 2em;
      font-weight: bold;
      @include bp_max(large) {
        display: block;
        margin: 0;
      }
    }
  }

  &__copyright {
    font-size: 0.7em;
    margin-bottom: 1rem;
  }

  &__bottom {
    margin-top: 0;
    padding: 16px 0;
    background-color: $darker-black;
    text-align: center;
    .row {
      padding: 0;
      align-items: center;
    }

    &-left {
      img {
        width: 200px;
      }
    }
    &-right {
      p {
        @include bp(medium) {
          justify-content: flex-end;
        }
        @include bp_max(smedium) {
          flex-direction: column;
        }
      }
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $white;
      }
      img {
        width: 220px;
        @include bp(smedium) {
          width: 180px;
          margin-left: 0.6rem;
        }
      }
    }

    p {
      display: flex;
      align-items: center;
      margin: 0;
      color: $white;
      font-size: 0.8em;
      @include bp_max(medium) {
        justify-content: center;
      }
    }
    img {
      @include opacity(1);
      transition: $global-transition;
      @extend %backface;
      &:hover {
        @include opacity(0.6);
      }
    }
  }
}
