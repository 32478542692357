/* Header
================================================== */
header {
  position: relative;
  background-color: $white;
  font-family: $primary-font;
  font-weight: 400;
  top: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  transition: $global-transition;

  .row {
    align-items: center;
    padding: 0.8rem;
    justify-content: space-between;
  }

  .menu.active & {
    padding: 0;
  }

  .column {
    @include bp(xxlarge) {
      .shrink & {
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
      }
    }
    @include bp(xxxlarge) {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
    }
  }

  &.shrink,
  .menu-active & {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: space-between;
    z-index: 99;
    transition: $global-transition;

    @include bp(xxlarge) {
      position: fixed;
    }

    .header__logo {
      position: relative;
      transition: $global-transition;

      img {
        width: 190px;
      }

      p {
        font-size: 1rem;
      }

      .vertical-div {
        height: 60px !important;
      }

      .conf-info {
        position: relative;
      }
    }

    .nav--main {
      &.active {
        @include bp_max(large) {
          top: 4em;
        }
      }
    }
  }
  &.shrink {
    .header__top {
      padding: 0;
    }
  }
  .menu-active & {
    .nav--main {
      .button {
        font-size: 1.2rem !important;
      }
    }
  }
}

.header {
  /*&__wrap {
    @extend %flex;
    align-items: center;
    justify-content: center;

    @include bp(xlarge) {
      justify-content: space-around;
    }
  }*/
  &__top {
    z-index: 90;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    //box-shadow: 0 9px 9px 3px darken($dark-blue, 3%);

    @include bp(xlarge) {
      flex-direction: row;
    }
  }
  &__logo {
    position: relative;
    z-index: 70;
    @extend %flex;
    justify-content: center;
    align-items: center;
    transition: $global-transition;
    padding: 0.5rem 1rem;

    p {
      margin: 0;
      text-align: center;
      font-size: 1rem;
      line-height: 1.7rem;
      @include bp(medium) {
        font-size: 0.9rem;
      }
      @include bp(mlarge) {
        font-size: 1rem;
      }
      @include bp(xlarge) {
        font-size: 0.9rem;
      }
      @include bp(xxlarge) {
        font-size: 1rem;
      }

      &:first-of-type {
        margin-right: 0.5rem;
      }
    }
    a {
      display: block;
      transition: $global-transition;
    }
    img {
      display: block;
      width: 240px;
      padding: 0 0 1rem 0;
      @include bp(medium) {
        width: 150px;
        padding: 0;
      }
      @include bp(xxlarge) {
        width: 270px;
      }
    }

    .conf-info {
      .paragraphs {
        display: inline-block;
        vertical-align: middle;
      }
      &::before {
        display: none;

        @include bp(medium) {
          content: ' ';
          display: inline-block;
          height: 80px;
          width: 8px;
          background: rgb(4, 123, 193);
          background: linear-gradient(
            180deg,
            rgba(4, 123, 193, 1) 0%,
            rgba(144, 224, 90, 1) 100%
          );
          margin: 0 10px;
          vertical-align: middle;
        }
      }
    }
  }

  &__right {
    padding: 14px 0;
    width: 100%;
    @include bp(xlarge) {
      width: auto;
      text-align: right;
    }
    @include bp_max(large) {
      .button--stand,
      .button--int {
        display: none;
      }
    }
    p,
    a {
      margin: 0;
    }
  }

  .social {
    margin: 0 2rem 0 0;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include bp(medium) {
      flex-direction: row;
    }
    /*@include bp(xxlarge) {
      margin-top: 2.25rem;
    }*/
    .custom {
      display: flex;
    }
    .button {
      //width: 230px;
      margin: 0.5rem;
      @include bp(medium) {
        margin: 0 0.5rem;
      }
      &--select {
        display: none;
        @include bp(large) {
          display: inline-flex;
        }
        @include bp(xlarge) {
          display: none;
        }
      }
    }
  }

  &__events-list {
    display: none;
    @include bp(xlarge) {
      display: block;
      position: relative;
      z-index: 80;
    }
    @include bp(xxlarge) {
      position: absolute;
      top: 0;
      right: 0;
    }
    .events-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      list-style: none;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(218, 218, 218, 1) 100%
      );
      //background-size: 80%;
      background-position: 100% 50%;
      background-repeat: no-repeat;
      li {
        position: relative;
        padding: 0.5rem;
        margin: 0 1rem;
        min-width: 230px;
        &:hover {
          .events-list__link {
            display: flex;
          }
        }
      }
      &__title {
        margin: 0;
        font-family: $tertiary-font;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.1rem;
        letter-spacing: 1.5px;
        text-align: center;
        cursor: pointer;
      }
      &__link {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: none;
        flex-direction: column;
        width: 100%;
        padding: 1rem;
        border-radius: 16px;
        background-color: $white;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        &.active {
          display: flex;
        }
        img {
          height: 70px;
        }
        .button {
          margin: 1rem 0 0 0;
        }
      }
    }
  }
}

.vertical-div {
  height: 80px;
  width: 8px;
  background: rgb(4, 123, 193);
  background: linear-gradient(
    180deg,
    rgba(4, 123, 193, 1) 0%,
    rgba(144, 224, 90, 1) 100%
  );
  margin: 0 10px;
}
