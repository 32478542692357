/* News & Partners
================================================== */
.latest-news {
  &:hover {
    .tns-nav {
      opacity: 1;
    }
  }
  ul.latestnews-items {
    flex-flow: column !important;
    justify-content: normal !important;
    align-items: normal !important;
    flex-wrap: wrap !important;
    overflow: visible !important;
    @include bp(large) {
      flex-flow: row !important;
      flex-wrap: wrap !important;
    }
  }
  .latestnews-item {
    flex: 1 1 0px;
    min-width: 0;
    max-width: 100%;
    margin-bottom: 2rem !important;
    padding: 0 0.9375rem 2rem !important;
    @include bp(large) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-bottom: 0rem !important;
    }
    &::before {
      display: none;
    }

    & > a {
      display: block;
      height: 100%;
      &:hover {
        strong {
          margin-left: 0.5rem;
        }
        .link {
          a {
            background-position: 5% 0;
          }
        }
      }
    }
    a {
      color: $black;
    }
    .newsSection & {
      border: 0;
    }
    &.active {
      opacity: 1 !important;
    }
  }
  .newshead {
    float: none !important;
    margin: 0 !important;
  }
  .picture {
    width: 100%;
    max-width: 100% !important;
    max-height: 240px !important;
    margin-bottom: 1.5rem;
    background-color: $white;
    a,
    picture {
      display: inline-block;
      width: 100%;
      height: 240px !important;
    }
  }
  .innerpicture {
    width: 100%;
    &:after {
      content: '';
      display: block;
      padding-bottom: 60%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .news {
    position: relative !important;
    overflow: hidden;
    height: 100% !important;
    margin: 0 !important;
    margin-bottom: 0 0 1rem !important;
    background-color: $off-white !important;
    border: none !important;
    border-radius: 8px;
  }
  .innernews {
    border-radius: 0 !important;
  }
  .newsinfo {
    padding: 0.5rem 2rem 4rem !important;
  }
  .newstitle {
    font-size: 1.2rem;
    font-family: $secondary-font !important;
    font-weight: bold !important;
    line-height: 1.2 !important;
    margin: 0 0 1rem 0 !important;
    letter-spacing: 0;
  }
  .newsintro {
    line-height: 1.6 !important;
    font-size: 1.1rem;
    font-size: $base-font-size;
    color: $black;
  }
  .detail_category,
  .detail_tags {
    display: inline-flex;
    padding: 0.8rem;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 1.1rem;
    color: $white;
  }
  .detail_category {
    background: $dark-blue;
  }
  .detail_tags {
    background: $primary-colour;
  }
  .detail_date {
    display: block;
    margin: 1em 0;
    font-family: $primary-font;
    text-transform: uppercase;
  }
  .detail_jfield_text,
  .detail_jfield_textarea {
    font-size: 0.9rem;
  }
  .detail_tags {
    .detail_data:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-bottom: 0.05rem;
      margin-right: 0.5rem;
      background-color: $black;
      border-radius: 50%;
    }
  }
  &__company,
  &__job {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
  &__job {
    font-weight: 500;
  }
  .delimiter {
    display: none;
  }
  .link {
    margin-top: 1rem !important;
    z-index: 1 !important;
    //bottom: 0 !important;
    //width: calc(100% - 2rem) !important;
    /*a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @extend .button;
    }*/
    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0.5rem 0;
      border-radius: 0;
      text-align: center;
      color: $white;
    }
    svg {
      height: 16px;
      margin-right: 0.5rem;
    }
  }
  &--full {
    .newslist {
      ul.latestnews-items {
        flex-flow: row wrap !important;
      }
      .latestnews-item {
        @include bp(medium) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(large) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
  }
  &--image-contain {
    .innerpicture {
      img {
        object-fit: contain;
        padding: 1rem;
      }
    }
  }
  &--cards,
  &--profile {
    .detail_tags {
      display: inline-flex;
      font-weight: 600;
      font-size: 0.8rem;
      opacity: 1;
      padding: 0.125rem 0;
      color: $black;
      background-color: transparent;
      transition: $global-transition;
    }
  }
  &--cards {
    .newsinfo {
      height: 100%;
      padding: 0.5rem 2rem 4rem !important;
    }
    &-1 {
      .link {
        a {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 0.5rem 0;
          border-radius: 0;
          text-align: center;
          color: $white;
        }
      }
    }
    &-2 {
      .latestnews-item {
        & > a {
          &:hover {
            .link {
              a {
                color: $tertiary-colour !important;
                border-bottom-color: $tertiary-colour;
              }
            }
          }
        }
      }
      .news {
        background-color: $white !important;
      }
      .newsinfo {
        padding-bottom: 6rem !important;
      }
      .picture {
        max-height: 140px !important;
        margin: 0 !important;
        a {
          height: 140px !important;
          padding: 2rem;
        }
        img {
          object-position: left;
        }
      }
      .link {
        width: auto !important;
        a {
          position: absolute;
          width: auto;
          right: 2rem;
          bottom: 2rem;
          left: 2rem;
          margin-bottom: 0;
          padding: 8px;
          background: transparent;
          border-bottom: 2px solid $black;
          border-radius: 0;
          color: $black !important;
          &:hover {
            background: transparent;
            color: $tertiary-colour !important;
            border-bottom-color: $tertiary-colour;
          }
        }
      }
    }
  }
  &__event-summary {
    font-size: 1rem;
  }
  &--three-columns,
  &--3-1 {
    ul.latestnews-items {
      flex-flow: row !important;
      flex-wrap: wrap !important;
      @include bp_max(large) {
        flex-direction: column !important;
      }
    }
    .latestnews-item {
      @include bp(large) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
      @include bp_max(large) {
        flex: 1 1 0px !important;
        min-width: 0 !important;
        max-width: 100% !important;
      }
    }
  }
  &--four-columns,
  &--4-3-2-1 {
    ul.latestnews-items {
      flex-flow: row !important;
      flex-wrap: wrap !important;
      @include bp_max(medium) {
        flex-direction: column !important;
      }
    }
    .latestnews-item {
      padding: 0 1rem 2rem !important;
      @include bp_max(medium) {
        flex: 1 1 0px !important;
        min-width: 0 !important;
        max-width: 100% !important;
      }
      @include bp(medium) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0rem !important;
      }
      @include bp(large) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
      @include bp(xxlarge) {
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 0rem !important;
      }
    }
  }
  &--4-2-1 {
    ul.latestnews-items {
      flex-flow: row !important;
      flex-wrap: wrap !important;
      @include bp_max(medium) {
        flex-direction: column !important;
      }
    }
    .latestnews-item {
      padding: 0 1rem 2rem !important;
      @include bp_max(medium) {
        flex: 1 1 0px !important;
        min-width: 0 !important;
        max-width: 100% !important;
      }
      @include bp(medium) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0rem !important;
      }
      @include bp(xlarge) {
        flex: 0 0 25%;
        max-width: 25%;
        margin-bottom: 0rem !important;
      }
    }
  }
  &--title-large {
    .newstitle {
      font-size: 1.6rem;
    }
  }
  &--profile {
    .picture {
      max-height: 320px !important;
      a,
      picture {
        height: 320px !important;
      }
    }
  }
  &--border {
    .news {
      border: 2px solid $off-white !important;
    }
  }
  &--image-height {
    .innerpicture {
      a,
      picture {
        display: flex !important;
        align-items: center;
      }
      img {
        max-height: 125px !important;
      }
    }
  }
  &--height {
    .latestnews-items {
      display: flex;
    }
    .latestnews-item {
      flex: 1;
    }
  }
  .event-coming-soon {
    display: none;
    .detail_label {
      font-size: 1rem;
      font-weight: bold;
    }
    .detail_data {
      display: none;
    }
  }
  &--slider {
    ul.latestnews-items,
    .tns-ovh {
      overflow: visible !important;
    }
  }
  &--slider-three {
    ul.latestnews-items {
      flex-flow: row !important;
      width: calc(1600%) !important;
      @include bp(mlarge) {
        width: calc(800%) !important;
      }
      @include bp(large) {
        width: calc(533.333%) !important;
      }
    }
  }
  .tns-nav {
    opacity: 0;
    transition: $global-transition;
    button {
      background: transparent;
      opacity: 1;
      padding: 5px 5px 5px 5px;
      box-shadow: none;
      border: 2px solid $primary-highlight;
      border-radius: 50px;
      margin: 4px;
    }
    & > .tns-nav-active {
      background: transparent;
      border: 2px solid $secondary-colour;
    }
  }
}

/* News page
================================================== */
.latest-news {
  .row {
    padding: 6em 0;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    h2 {
      margin: 0;
    }
  }
}
#news-article {
  background-color: $medium-grey;
}
.news-item-article {
  padding-bottom: 3rem;
  .main-content {
    flex-direction: column;
    padding: 0;
  }
  .com-content-image,
  .com-content-article {
    max-width: 960px;
    width: 100%;
    margin: auto;
  }
  .com-content-image {
    margin-bottom: 0;
    figure {
      position: relative;
      margin: 0;
      background-color: $white;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 600px;
      object-fit: cover;
    }
  }
  .com-content-article {
    background-color: $white;
    padding: 3rem;
  }

  h1 {
    margin-top: 2rem;
    color: $primary-colour;
    font-size: 2.5rem;
    font-weight: 700;
  }
  .article-info.muted {
    display: block;
    color: $black;
    margin-bottom: 2em;
  }
  .share-buttons {
    margin-top: 4rem;
    text-align: center;
    .button {
      margin-top: 1rem;
    }

    .st-btn {
      background-color: $primary-colour !important;
      border-radius: 24px !important;
      margin: 1rem;
    }
  }
  &--profile {
    .com-content-image {
      img {
        object-fit: contain;
      }
    }
  }
}

.article-info.muted {
  display: none;
}
