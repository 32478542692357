/* Page Specific Styling
================================================== */

// About page

.venue-image {
  background-image: url('#{$image-directory}Marina_Bay_Sands_Property_Overview_Shot_3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @include bp_max(large) {
    height: 600px;
  }
}

.venue-text {
  padding: 6rem 2rem;
  /*@include bp(xxxlarge) {
    margin: 0 0 0 13rem;
}*/
}

.venue-sub {
  position: absolute;
  bottom: 0;
  right: 0;

  li {
    padding: 1.2rem 5rem 1.2rem 1rem !important;
  }
}

// Visit page

.singapore-info {
  @include bp(large) {
    padding: 0rem 5rem 5rem 5rem;
  }
}

// Trimble Club Programme

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 0;

  h4 {
    font-size: 2.5rem;
  }

  h5 {
    font-size: 1.7rem;
  }
}

.trimble-table {
  table {
    border: none !important;
    font-size: 0.9rem;

    @include bp(smedium) {
      font-size: inherit;
    }
  }

  th {
    background: none;
    font-size: 1.5rem;
    color: $primary-highlight;
  }
  tr {
    border: none;
  }

  td {
    border-bottom: none;
  }

  td:nth-child(1) {
    border-right: 4px solid $primary-highlight;
  }

  li {
    list-style: circle;

    margin: 0 0.1rem 0 0.25rem;
    padding: 0.5rem 0;

    @include bp(medium) {
      margin: 0 2rem 0 2rem;
      padding: 0.5rem;
    }
  }
}
