/* Sections 
================================================== */
section,
.section {
  position: relative;
  overflow: hidden;
  padding: 6rem 0;
}

.section {
  &--colour {
    background-color: $primary-colour;

    &-darker {
      background-color: $dark-blue;
    }
  }

  &--whitebdy {
    color: $white;

    h2 {
      color: $white;
    }
  }

  &--narrow {
    padding: 3rem;
  }

  &--text-center {
    text-align: center;
  }

  &--pattern-bg-right {
    background-image: url('#{$image-directory}pattern-bg-fade-down.jpg');
    background-size: contain;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    @include bp(large) {
      background-image: url('#{$image-directory}pattern-bg-fade-right.jpg');
      background-position: 100% 50%;
      background-size: cover;
    }
    @include bp(xlarge) {
      background-size: contain;
    }
  }

  &--pattern-bg-bottom {
    background-image: url('#{$image-directory}pattern-bg-fade-down.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &--gradient {
    background: hsla(202, 67%, 45%, 1);
    background: linear-gradient(
      hsla(202, 67%, 45%, 1) 0%,
      rgb(43, 190, 202) 53%,
      hsla(0, 0%, 100%, 1) 100%
    );
    background: -moz-linear-gradient(
      hsla(202, 67%, 45%, 1) 0%,
      rgb(43, 190, 202) 53%,
      hsla(0, 0%, 100%, 1) 100%
    );

    background: -webkit-linear-gradient(
      hsla(202, 67%, 45%, 1) 0%,
      rgb(43, 190, 202) 53%,
      hsla(0, 0%, 100%, 1) 100%
    );
    background-size: cover;
    background-repeat: no-repeat;
  }

  &--intro {
    padding: 0 2rem 5rem 2rem !important;
  }

  &--testimonial {
    margin: 5rem 0;
  }

  &--lightbg {
    background-color: $light-blue;
  }

  &--fancylist {
    ul {
      list-style: none;
    }
    li {
      color: $dark-grey;
      padding: 0.4rem 1rem;
      margin: 1rem 0;
      border-left: 4px solid $primary-colour;
      background-color: $light-grey;
    }

    &-secondary {
      li {
        border-left: 4px solid $secondary-colour;
      }
    }

    &-lightbg {
      li {
        color: $dark-grey;
        padding: 0.4rem 1rem;
        margin: 1rem 0;
        background-color: $white;
      }
    }
  }

  &--cta {
    padding-bottom: 0;
  }

  &--map {
    /* {
	  @include bp_max(large) {
		text-align: center;
	  }
	} */
    p {
      color: $black;
    }
    .map {
      @include bp_max(large) {
        margin-bottom: 2em;
      }
      &__wrap {
        position: relative;
        min-height: 320px;
        height: 100%;
        background: url('#{$image-directory}map-bg.jpg');
        background-size: cover;
      }
      img {
        @extend %vca;
        width: 120px;
      }
    }
    .mapDetails {
      .card {
        margin: 0;
      }

      .right {
        text-align: center;
        @include bp(smedium) {
          text-align: right;
        }
      }
      img {
        width: 240px;
      }
      &__travel {
        margin-bottom: 2em;
      }
    }
  }
}

.button-align-right {
  @include bp(large) {
    text-align: right;
  }
}

/* Countdown
================================================== */
.section--countdown {
  padding: 3rem 0;

  h3,
  h5,
  h6 {
    margin: 0.5rem 0;
  }
  h5,
  h6 {
    font-size: 1.1rem;
    @include bp(medium) {
      font-size: 1.2rem;
    }
    @include bp(mlarge) {
      font-size: 1.4rem;
    }
    @include bp(xlarge) {
      font-size: 1.7rem;
    }
  }
  h5 {
    color: $white;
  }
  h6 {
    color: $primary-highlight;
  }

  img {
    height: 90px;
  }
  .button {
    margin: 1rem 0 0 0;
  }
}
.countdown {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 2.3rem;
  flex-direction: row;
  @include bp(large) {
    font-size: 2.6rem;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__event {
    display: flex;
    justify-content: center;
    @include bp(large) {
      padding-right: 1.5rem;
    }
  }

  &__logo-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__number {
    min-width: 80px;
    margin-bottom: 0.5rem;
    line-height: 100%;
    color: $white;
    font-weight: 600;
    font-family: $quartinary-font;
    font-size: 2.8rem;
  }
  &__text {
    color: $white;
    font-size: 0.3em;
    text-transform: uppercase;
    opacity: 0.5;
  }
  &__colon {
    width: 40px;
    color: white;
    margin-top: 0.4rem;
    font-size: 1.5rem;
  }
}

/* Sliders
================================================== */
.n2-section-smartslider {
  .n2-bullet,
  .nextend-arrow {
    transition: $global-transition !important;
  }
}

.slider {
  &--colocated {
    background: $primary-colour;
  }
}

// Testimonials

.testimonial {
  .quote-mark {
    transform: none !important;
    left: 0 !important;
    right: 0;
    width: 100% !important;
    height: auto !important;
    color: $tertiary-colour !important;
    font-family: $tertiary-font !important;
    font-size: 180px !important;
    h2 {
      line-height: 1.2 !important;
    }
  }
  &__details {
    bottom: -10px;
    top: auto !important;
    transform: none !important;
    max-width: 90%;
    @include bp(mlarge) {
      max-width: 60%;
      bottom: -2rem;
    }
    & > div {
      border-left: 6px solid $tertiary-colour !important;
    }
    .n2-ss-layer-row-inner {
      flex-wrap: nowrap !important;
    }
  }
  &__logo {
    width: 60px !important;
    height: 60px !important;
    * {
      height: 100% !important;
    }
    picture {
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid $secondary-colour;
      box-sizing: border-box !important;
      padding: 4px !important;
    }
    img {
      object-fit: contain;
    }
  }
}

.announcements {
  .n2-ss-section-main-content {
    @include bp_max(xlarge) {
      flex-direction: column-reverse !important;
    }
  }
  &__content {
    position: relative !important;
    z-index: 20 !important;
    display: flex !important;
    align-items: center !important;
    width: calc(100% - 4rem) !important;
    height: 100% !important;
    margin-right: auto !important;
    padding: 6rem 2rem !important;
    background-color: $secondary-colour;
    border-right: 4px solid $secondary-colour;
    @include bp(xlarge) {
      width: calc(40% - 2rem) !important;
      height: 100% !important;
      padding-right: 0 !important;
      padding-bottom: 8rem !important;
    }
    .subheading {
      margin-bottom: 0.5rem !important;
      font-family: $primary-font !important;
      text-transform: uppercase !important;
      color: $tertiary-colour !important;
      font-weight: bold !important;
      letter-spacing: 3px !important;
      font-size: 1.2rem !important;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  &__title {
    font-family: $secondary-font !important;
    line-height: $base-heading-line-height !important;
    font-weight: $base-heading-font-weight !important;
    text-transform: uppercase !important;
    h2 {
      font-size: $base-h1-font-size-mobile !important;
      @include bp(medium) {
        font-size: $base-h1-font-size-tablet !important;
      }
      @include bp(large) {
        font-size: $base-h1-font-size !important;
      }
    }
  }
  &__shape {
    display: none;
    position: absolute !important;
    z-index: 30 !important;
    left: 40%;
    top: 0;
    bottom: 0;
    width: 5rem;
    height: 100%;
    background: url('#{$image-directory}triangle-blue-right.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    @include bp(xlarge) {
      display: block;
    }
  }
  &__bg {
    z-index: 10 !important;
    width: 60% !important;
    height: 100% !important;
    left: auto !important;
    right: 0 !important;
    transform: none !important;
    background-color: $white;
    @include bp_max(xlarge) {
      position: static !important;
      width: 100% !important;
      max-height: 400px;
    }
    * {
      width: 100%;
      height: 100% !important;
    }
    img {
      object-fit: cover;
      @include bp(xlarge) {
        object-position: 100% 50%;
      }
      @include bp(xxxlarge) {
        object-position: 80% 50%;
      }
    }
    &--contain {
      img {
        object-fit: contain;
      }
    }
  }
  .button {
    margin-bottom: 0 !important;
  }
  .nextend-arrow {
    opacity: 0.5 !important;
    &:hover {
      opacity: 1 !important;
    }
  }
  &-slide {
    &--2 {
      .announcements__bg {
        img {
          @include bp(xlarge) {
            object-position: 100% 50%;
          }
          @include bp(xxxlarge) {
            object-position: 80% 50%;
          }
        }
      }
    }
  }
}

/* Stats
================================================== */
.stats {
  .row {
    padding: 1rem 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @include opacity(0);
    transition: $global-transition;
    @include bp_max(smedium) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
    }
    @include bp(mlarge) {
      margin-left: 4rem;
    }
  }
  .stat {
    width: 100%;
    list-style: none;
    margin: 1rem 0;
    padding: 0 1.5rem;
    @include bp(smedium) {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    &__number {
      display: flex;
      align-items: center;
    }
  }
  p {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-family: $primary-font;
    text-transform: uppercase;
  }
  strong {
    display: block;
    font-size: 3rem;
    font-weight: 700;
    font-family: $tertiary-font;
  }
  img {
    display: block;
  }
  svg {
    width: 56px;
    min-width: 56px;
    margin-right: 1.5rem;
    * {
      fill: $black;
    }
  }

  &--about {
    .row {
      padding: 1rem 0;
    }
    ul {
      display: flex;
      justify-content: space-evenly;
      background-color: $primary-colour;
      border-radius: 24px;

      @include opacity(0);
      transition: $global-transition;
      @include bp_max(mlarge) {
        flex-wrap: wrap;
        justify-content: center;
      }
      @include bp(xlarge) {
        flex-direction: column;
        background-color: transparent;
      }
    }
    .stat {
      width: 100%;
      list-style: none;
      margin: 0.5rem 0;
      padding: 0 1.5rem;

      @include bp(xlarge) {
        background-color: $primary-colour;
        border-radius: 100%;
        width: 250px;
        height: 250px;
        padding: 0;
      }
      //border-left: 4px solid $primary-colour;
      @include bp_max(mlarge) {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp_max(smedium) {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    p {
      text-align: center;
      margin: 0.5rem 0;
      font-size: 1.4rem;
      color: $white;
      padding: 1rem;
    }
    strong {
      display: block;
      font-size: 3rem;
      font-weight: 700;
      font-family: $secondary-font;
    }
    img {
      display: block;
      svg {
        fill: white;
      }
    }
  }
}

#register-interest {
  padding: 0;
}
