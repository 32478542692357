/* Main
================================================== */
.body-wrap {
  position: relative;
  overflow: clip;
  @include transform-origin(center top);
  transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  @include opacity(1);
  .menu-active & {
    @include scale(1.05);
    @include opacity(0);
  }
}

.main {
  &-content {
    &.column {
      padding: 0 2rem;
    }
  }
}
main {
  padding: 8rem 0;

  &.pattern-bg {
    background-image: url('#{$image-directory}pattern-bg-fade-down.jpg');
    background-size: contain;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    @include bp(large) {
      background-image: url('#{$image-directory}pattern-bg-fade-right.jpg');
      background-position: 100% 50%;
      background-size: cover;
    }
    @include bp(xlarge) {
      background-size: contain;
    }
  }
}

.label-warning {
  display: none;
}
