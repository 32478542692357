/*body{
  height:300vh;
  background: linear-gradient(45deg,  violet , dodgerblue, hotpink);
}*/

.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .popup {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 700px;
    //padding: 4em 2em;
    background-color: transparent;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include bp_max(small) {
      font-size: 0.8em;
    }
    &--wide {
      max-width: 1024px;
    }
  }

  .title {
    margin-bottom: 8px;
    @include bp_max(smedium) {
      font-size: 1.4em;
    }
    &:after {
      display: none;
    }
  }

  h4 {
    width: 100%;
  }

  p {
    color: $black;
  }

  .button {
    margin-bottom: 0;
  }

  .close {
    position: absolute;
    top: 1em;
    right: 1em;
    height: auto;
    margin: 0;
    padding: 0.25rem 1rem;
    line-height: 100%;
    color: $white;
    //background-color: $secondary-colour;
    text-decoration: none;
    text-transform: uppercase;
    font-family: sans-serif;
    //border: none;
    &:after {
      display: none;
    }
  }
}
