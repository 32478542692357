/* Navigation
================================================== */
body.menu-active {
  overflow: hidden;
}

.nav {
  @include flex();
  justify-content: space-between;
  margin: 0;
  li {
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    position: relative;
    margin: 0;
    text-align: center;
    list-style: none;
  }
  a {
    display: block;
    padding: 8px;
    color: $white;
    font-size: 1em;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    @include transition(color 400ms ease-in-out);
  }
  a:hover,
  .current > a,
  .active > a {
    color: $primary-highlight;
  }
  ul {
    display: none;
    width: 100%;
    position: static;
    margin: 0;
    font-size: 0.8em;
    @include bp(large) {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      min-width: 100%;
      width: auto;
      font-size: 1em;
    }
    &.active {
      @include bp_max(large) {
        display: block;
      }
    }
    li {
      width: 100%;
    }
    a {
      //padding: 16px;
    }
  }
  li:hover > ul {
    @include bp(large) {
      display: block;
    }
  }
  & > * {
    overflow: visable;
  }

  .item-415,
  .item-495 {
    max-width: 80px;
  }
  .home-link {
    img {
      width: 24px;
    }
    .image-title {
      display: none;
    }
  }

  // Down Arrow
  svg {
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 6px;
    //margin-bottom: 2px;
    fill: $white;
    @include bp(xlarge) {
      width: 10px;
      //margin-bottom: 4px;
    }
  }
}

/* Main Navigation
================================================== */
.nav--main {
  display: block;

  @include bp_max(smedium) {
    position: relative !important;
  }
  &.shrink {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 90;
    box-shadow: (0px 6px 14px 0px rgba(79, 77, 87, 0.4));
  }

  .nav__outer {
    @include flex();
    flex-grow: 1;
    background-color: $dark-blue;
    box-shadow: 0 2px 11px 3px (rgba(0, 0, 0, 0.2));

    @include bp_max(large) {
      flex-direction: row-reverse;
    }
  }

  .row {
    width: 100%;
    @include bp(large) {
      padding: 0;
    }
  }

  .column {
    @include bp_max(large) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .nav__wrap {
    @include bp_max(large) {
      display: none;
    }
  }

  .moduletable {
    width: 100%;
  }

  .nav,
  a {
    height: 100%;
  }

  .nav {
    @include bp_max(large) {
      min-height: 100vh;
      overflow: auto;
    }
  }

  a {
    position: relative;
    @include flex();
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem;
    color: $white;
    @include bp(large) {
      font-size: 0.8em;
    }
    @include bp(xlarge) {
      font-size: 0.9em;
    }

    .shrink & {
      padding: 0.8rem;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 0;
      height: 6px;
      margin: auto;
      background: $primary-highlight;

      @include transition(width 0.3s);
    }
  }

  a:hover,
  .current > a,
  .active > a {
    color: $primary-highlight;
    &:after {
      width: 100%;
    }
  }

  .button {
    &--select {
      @include bp(large) {
        display: none;
      }
    }
    &--outline {
      border-color: $white;
      &:hover {
        color: $white;
        border-color: $primary-colour;
      }
      &:visited {
        color: $white;
      }
      &:after {
        display: none;
      }
    }
  }
}

// DCA
/*.dca, .current > .dca, .active > .dca {
    &:after {
      background: @tertiary_colour;
    } 
  } */

.nav ul {
  background-color: $dark-blue;
  box-shadow: (0px 6px 14px 0px rgba(79, 77, 87, 0.4));
  font-size: 0.8em;
  a {
    color: $white;
  }
}

/* Overlay Navigation
================================================== */
.nav--overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 0;
  margin: auto;
  overflow: hidden;
  background-color: $white;

  &.active {
    height: 100%;
    overflow: initial;
  }
  .nav__wrap {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 40px 0;
    overflow-y: auto;
    overflow-x: hidden;

    .header__buttons {
      .button--select {
        display: none;
      }
    }
  }
  .vertical-align {
    display: block;
    width: 100%;
    height: auto;
    z-index: 5;
  }
  .columns {
    transform: translateY(-300%);
    -webkit-transform: translateY(-300%);
    -moz-transform: translateY(-300%);
    -ms-transform: translateY(-300%);
    -o-transform: translateY(-300%);
    transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -ms-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    .menu-active & {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      opacity: 1;
    }
  }
  .moduletable,
  .switch-show {
    transform: translateY(40px);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    .menu-active & {
      animation: fadeInUp 1s 0.5s forwards;
      -webkit-animation: fadeInUp 1s 0.5s forwards;
      -moz-animation: fadeInUp 1s 0.5s forwards;
      -ms-animation: fadeInUp 1s 0.5s forwards;
    }
  }
  .nav {
    display: flex;
    flex-direction: column;
    //padding: 0 2rem;
    //text-align: center;
    & > li {
      //flex-basis: auto;
      //width: 50%;
      margin-bottom: 24px;
      text-align: center;
      /* @include bp_max(smedium) {
            	width: 33.3%;
            }
            @include bp(medium) {
            	width: 16.6%;
            } */
    }
    a {
      font-size: 1.2em;
      color: $primary-colour;
      &:hover {
        color: $secondary-colour;
      }
    }
    ul {
      background-color: transparent;
      box-shadow: none;
      position: static;
      width: 100%;
      display: block;
      margin: 0;
      font-size: 0.7em;
      li {
        margin: 0;
      }
      a {
        padding: 8px 11px;
        font-size: 1em;
      }
    }

    .button {
      padding: 14px 0;
    }
  }
  svg {
    display: none;
  }

  .register-buttons {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 24px;
    text-align: center;
    & > div {
      justify-content: center;
    }
    a {
      max-width: 320px;
      width: 100%;
      margin: 0 0 24px 0;
      @include bp(smedium) {
        width: 48%;
        margin: 1%;
      }
    }
  }
  .menu-link {
    margin: 0 auto;
    background: transparent;
    p {
      display: inline-block;
      color: $primary-colour;
    }
    span,
    span:before,
    span:after {
      background-color: $primary-colour;
    }
  }

  .social {
    p {
      display: block;
    }
  }

  .switch-show {
    a {
      background-color: $white !important;
    }
    &__logo {
      @include bp_max(smedium) {
        width: 140px;
      }
    }
    &__icon {
      display: none;
    }
  }
}

/* 404 Page Navigation
================================================== */
.error-page {
  .nav {
    position: static;
    svg {
      display: none;
    }
    a {
      padding: 8px;
      color: $primary-colour;
      &:hover {
        color: $secondary-colour;
      }
    }
    li,
    ul {
      position: static;
      min-width: 100%;
      margin: 0;
      display: block;
      text-align: center;
      background: transparent;
    }
    ul {
      margin-bottom: 24px;
      font-size: 0.9em;
    }
  }
}

/* Hamburger
================================================== */
.menu-link {
  @include flex();
  align-items: center;
  position: relative;
  cursor: pointer;
  min-width: 110px;
  margin: 0;
  padding: 20px 16px;
  outline: none;
  background: transparent !important;
  @include bp(large) {
    display: none;
  }

  p {
    margin: 0 36px 0 0;
    color: $white;
  }

  span,
  span:before,
  span:after {
    content: '';
    position: absolute;
    top: 46%;
    right: 0;
    display: inline-block;
    width: 24px;
    height: 3px;
    margin: 0 auto;
    background: $white;
    @include border-radius(1px);
    @include transition(all 500ms ease-in-out);
  }

  span {
    right: 1rem;
    &:before {
      top: -8px;
    }
    &:after {
      top: auto;
      bottom: -8px;
    }
  }

  &.active {
    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
      }
      &:before {
        rotate: 45deg;
      }

      &:after {
        rotate: -45deg;
      }
    }
  }
}

/* Select Event / Sidebar Navigation
================================================== */

.nav--sidebar {
  position: fixed;
  top: -20px;
  right: 0; // to open on the right of screen
  bottom: -20px;
  z-index: 130;
  overflow-y: scroll;
  width: 320px;
  padding: 40px 0;
  text-align: center;
  -webkit-transform: translateX(320px); // right side
  transform: translateX(320px); // right side
  @include transition(all 0.6s ease-in-out);
  background: rgb(4, 123, 193);
  background: linear-gradient(
    180deg,
    rgba(4, 123, 193, 1) 0%,
    rgba(144, 224, 90, 1) 100%
  );
  @include box-shadow(inset 0px 0px 24px 0px rgba(60, 60, 59, 0.5));
  .sidebar-menu-active & {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  h3,
  p {
    margin: 0;
    padding: 16px 40px;
  }
  h5 {
    margin: 0 1rem 1rem;
    font-size: 1.4rem;
    color: $white;
  }
  p {
    display: block;
  }
  a {
    display: block;
    width: 100%;
  }
  img {
    width: 100%;
  }
  .close-button {
    display: block !important;
    height: 30px;
    margin-top: 0.5rem;
    background: none;
    cursor: pointer;
  }

  .close-button::before {
    content: '\2715';
    font-size: 1.6rem;
    line-height: 1rem;
  }
}

/* Animation
================================================== */
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
