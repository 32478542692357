/* Components
================================================== */

/* Rounded cards */

.card {
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 7px 30px -10px $dark-grey;
  background-color: white;

  &--colour {
    background: $dark-blue;
    color: white;

    &-primary {
      background: $primary-colour;
      p {
        color: white !important;
      }
    }
  }

  &--small {
    height: 200px;
  }

  &__img {
    border-radius: 24px 24px 0 0;

    &-bottom {
      border-radius: 0 0 24px 24px;
    }
  }

  &__text {
    padding: 2rem;
  }
  &__subtitle {
    padding: 1rem;
    font-size: 1.6rem;
    text-align: center;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}

/* CTA cards */
.cta-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 !important;

  @include bp(large) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }

  a {
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: inherit;
  }
}

.cta {
  width: calc(100% - 2rem);
  height: 300px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 16px;
  overflow: hidden;
  background: $white;

  &:before {
    content: '';
    position: absolute;
    z-index: 20;
    inset: 0;
    background-color: $secondary-colour;
    opacity: 0.3;
  }

  img {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__text {
    position: absolute;
    z-index: 30;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $secondary-font;
    color: $white;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    font-weight: bold;
    * {
      margin: 0;
    }
  }
}

/* Logo buttons */

.btn-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  background: $white;
  border-radius: 24px;
  box-shadow: 0 7px 20px -10px $medium-grey;

  img {
    display: block;
    max-width: 75%;
    max-height: 75%;
    margin: auto !important;
  }

  &.square {
    max-width: 50%;
  }

  &--slider {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 130px; // only the height works on smart slider
    background: $white;
    border-radius: 24px;
    box-shadow: 0 7px 20px -10px $medium-grey;

    img {
      display: block;
      max-width: 88%;
      max-height: 88%;
      margin: auto !important;
    }
  }

  &--larger {
    img {
      max-width: 100%;
    }
  }

  &--companies {
    //max-width: 180px !important;
    // min-width: 200px;
    // min-height: 80px !important;

    width: 200px !important;
    height: 80px !important;
    @include bp(medium) {
      width: 300px !important;
      height: 180px !important;
    }
  }
}

// For square buttons

.square {
  max-width: 50%;
}

/* Sponsor tags */

// Platinum Sponsor

.sponsor-tag {
  padding: 0.8rem;
  border-radius: 10px;
  color: #81898dff;

  &--platinum {
    background-color: #dadfe6;
  }
}

.sidebar-fixed {
  display: none;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(0%);
  z-index: 120;
  @include bp(xlarge) {
    display: block;
  }
  &__wrap {
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .button {
    position: absolute;
    width: 240px;
    margin: 0;
    rotate: -90deg;
    background-color: $tertiary-colour;
    color: $white !important;
    &:hover {
      background-color: darken($tertiary-colour, 8%);
    }
  }
}

/* Social
================================================== */
.social {
  ul {
    margin: 0;
  }
  li {
    position: relative;
    list-style: none;
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 8px;
    font-size: 1.4em;
  }
  a {
    display: block;
    height: 100%;
    &:hover {
      svg {
        * {
          fill: $primary-colour;
        }
      }
    }
    svg {
      @extend %vca;
      width: 50%;
      height: 50%;
      * {
        fill: $primary-colour;
        @include transition(all 400ms ease-in-out);
      }
    }
  }
  .sidebar-fixed & {
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 170px;
    background-color: $white;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0 7px 20px -10px $dark-grey;

    h3 {
      display: none;
    }

    li {
      width: 44px;
      height: 48px;
      float: right;
      clear: right;
      margin: 0;
      padding: 0;
      text-align: center;
      @include transition(all 400ms ease-in-out);
    }
    a {
      display: block;
      margin: 0;
      //padding: 10px;
      color: $white;
      &:hover {
        svg {
          * {
            fill: $secondary-colour;
          }
        }
      }
    }
  }
  footer & {
    span {
      display: inline-block;
      margin-right: 8px;
    }
  }
}

// Team members

.team-members {
  .title-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $primary-colour;
    color: $white !important;
    margin-bottom: 1rem;

    .row-text {
      padding: 1.9rem;
    }
  }

  .spacer {
    display: none;

    @include bp(medium) {
      display: block;
    }
  }

  .card {
    &__img {
      width: 600px;
    }

    p {
      margin: 1rem 0;
    }

    hr {
      background-color: #5cb8ef !important;
      border: none;
      height: 1px;
      width: 70%;
    }
  }
}

// Scroll to top button

#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: $primary-highlight;
  opacity: 0.8;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 16px 18px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
  z-index: 96;
  svg {
    width: 14px;
    * {
      fill: $white;
    }
  }
}

#scrollToTopBtn:hover {
  background-color: $primary-colour;
}

// Cookie control

.ccc-content--dark {
  background-color: $dark-blue !important;
}

#ccc-icon.ccc-icon--dark {
  fill: $dark-blue !important;
}

#ccc-icon {
  margin: 0 !important;
}

// Accordion
.accordion {
  padding: 0;
  &-item {
    margin: 0;
  }
  &-title {
    border: 0;
    border-bottom: 2px solid $primary-colour !important;
    font-size: 1.1rem;
    font-family: $primary-font;
    color: $secondary-colour;
    &:before {
      margin-top: -0.6rem;
      font-weight: bold;
    }
  }
  &-content {
    border: 0 !important;
  }
}

// Tabs
.tabs {
  border: 0;
  li {
    margin: 0;
  }
  a {
    color: $secondary-colour;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1.5px;
    border-bottom: 2px solid $medium-grey !important;
  }
  &#faq {
    margin-bottom: 4rem;
    @include bp(large) {
      padding: 0 2rem 2rem 0;
      margin-bottom: 0;
    }
  }
  &--style-1 {
    .tabs-title > a:focus,
    .tabs-title > a[aria-selected='true'] {
      background-color: $white !important;
      border-bottom-color: $primary-highlight !important;
    }
    li {
      width: 100%;
      @include bp(mlarge) {
        width: calc(25% - 1rem);
        margin: 0.5rem;
      }
    }
    a {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 120px;
      border-bottom-width: 4px !important;
    }
    img {
      object-fit: cover;
      padding: 0.5rem;
    }
  }
}
.tabs-panel {
  padding: 0;
}
.tabs-content {
  padding: 2rem;
  border: 0;
}
