/* Banner / Slider
================================================== */
.banner {
  position: relative;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75% 50%;
  /*background-image: url('#{$image-directory}GCAKV--longbgbanner.webp');
  background-color: rgba($dark-blue, 0.6);

  &:before {
    content: '';
    background-color: rgba($dark-blue, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include bp(xlarge) {
      display: none;
    }
  }*/
  .row {
    width: 100%;
  }

  &__content {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__text {
    padding: 8rem 1rem 10rem;
    z-index: 2;
    @include bp(mlarge) {
      padding: 14rem 1rem 16rem;
    }

    * {
      color: $white;
      text-shadow: 0 0 16px rgba(0, 70, 110, 0.5);
    }

    h1 {
      margin: 0 0 1rem 0;
      max-width: 1024px;
      @include bp_max(medium) {
        font-size: 2rem;
      }
    }

    .subheading {
      @include bp_max(medium) {
        font-size: 0.8rem;
      }
    }

    &-details {
      display: none;
      margin: 3rem 0 1rem 0;

      h6 {
        margin: 0 !important;
        text-transform: none;
        line-height: 1.2;
      }

      p {
        margin: 0;
      }
    }

    .button-wrap {
      * {
        text-shadow: none;
      }
      p,
      a {
        margin: 0;
      }
      .button {
        margin-top: 1rem;
        font-size: 0.9rem;
        padding: 1.25rem 1.75rem;
        color: $white;
        background: transparent;
        border: 2px solid $white;
        &:hover {
          color: $white;
          background: $primary-colour;
          border: 2px solid $primary-colour;
          &:visited {
            color: $white;
          }
        }
        @include bp_max(mlarge) {
          background: linear-gradient(
            120deg,
            $tertiary-colour,
            $primary-colour
          );
          background-size: 150% 100%;
          background-position: 80% 0;
          border: none !important;
          &:hover {
            background: linear-gradient(
              120deg,
              $tertiary-colour,
              $primary-colour
            );
            background-size: 150% 100%;
            background-position: 5% 0;
          }
        }
      }
    }

    .co-located {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include bp(large) {
        flex-direction: row;
        justify-content: flex-start;
      }

      .btn-logo {
        margin-bottom: 1rem;

        @include bp(medium) {
          margin-right: 2rem;
        }
      }

      img {
        margin: 2rem 0;
        height: 100px;
      }
    }

    .event-logo--white {
      height: 100px;
      margin-top: 2rem;

      @include bp(medium) {
        margin: 2rem 0;
      }

      img {
        width: 400px;
        height: auto;
      }
    }
  }

  &--video {
    .n2-ss-slider,
    .n2-ss-slider-1,
    .n2-ss-slider-3 {
      display: block !important;
    }
  }
  &__video {
    position: absolute;
    z-index: 10;
    inset: 0;
    height: 100%;
    * {
      height: 100% !important;
    }
    ss3-force-full-width {
      transform: translate3d(0, 0px, 0px) !important;
      width: 100% !important;
    }
    .n2-ss-slide-background-video {
      top: auto !important;
      right: auto !important;
      bottom: 0 !important;
      left: auto !important;
      height: 115% !important;
      margin: auto !important;
      @include bp_max(medium) {
        object-position: 70% 50%;
      }
    }
  }

  &-mha {
    background-image: url('#{$image-directory}MHA KV-cleaner--longbgbanner.webp');

    .banner-text {
      h1 {
        margin: 0;

        font-size: 3.5rem !important;

        @include bp(medium) {
          font-size: $base-h1-font-size;
        }
      }

      @include bp(xlarge) {
        h1,
        h4 {
          line-height: 1.3;
        }
      }

      .co-located {
        .event-logo--white-mha {
          height: 100px;
          margin-top: 2rem;

          @include bp(medium) {
            margin: 2rem 0;
          }

          img {
            width: 350px !important;
            height: auto;
          }
        }
      }
    }
  }

  &-dca {
    background-image: url('#{$image-directory}DCA-longbgbanner-01.webp');

    /*:before {
      content: '';
      background-color: rgba($dark-grey, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      @include bp(xlarge) {
        display: none !important;
      }
    }*/

    .banner-text {
      h1 {
        line-height: 1;
        margin: 0;

        font-size: 3.5rem !important;
        text-align: center;

        @include bp(medium) {
          text-align: left;
          font-size: $base-h1-font-size;
        }
      }

      .co-located {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include bp(large) {
          flex-direction: row;
          justify-content: flex-start;
        }

        .png-img {
          height: auto !important;
        }
      }
    }
  }
}

/* Inner banner
================================================== */
.banner {
  &--inner {
    .banner__text {
      padding: 5rem 1rem !important;
    }
  }
}
/*.banner-inner {
  background-image: url('#{$image-directory}GCAKV--longbgbanner.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  //height: 550px;

  @include bp(medium) {
    height: 410px;
  }

  @include bp(xxxlarge) {
    background-image: url('#{$image-directory}GCA-KV-innerbanner.png');
  }

  .banner-text {
    //background-color: rgba($dark-blue, 0.6);
    padding: 0.5rem 0.5rem;
    margin-top: 1rem;

    @include bp(large) {
      margin: 0;
      background: none;
      padding: 2rem 1rem;
    }

    h1,
    h4,
    h6 {
      color: $white;
    }
    h1 {
      line-height: 1;
      margin: 0;
    }

    h4 {
      font-size: 2rem;
      text-transform: capitalize;
      margin: 0 !important;
      letter-spacing: 3px;

      @include bp(large) {
        font-size: $base-h4-font-size;
      }
    }

    h6 {
      text-transform: none;
      @include bp(large) {
        line-height: 0.5;
      }
    }

    .event-logo--white {
      height: 100px;
      margin: 1.5rem 0;

      @include bp(smedium) {
        margin: 0rem 0 3rem;
      }

      @include bp(medium) {
        margin: 0rem 0 3rem;
      }

      img {
        width: 400px;
        height: auto;
      }
    }
  }
}*/

// MHA Styles

.event-logo--white-mha {
  height: 100px;
  margin-top: 2rem;

  @include bp(medium) {
    margin: 2rem 0;
  }

  img {
    width: 350px !important;
    height: auto;
  }
}

// DCA Styles

.event-logo--white-dca {
  height: 150px;
  //margin-top: 1rem;

  @include bp(medium) {
    //margin: 2rem 0;
  }

  img {
    width: 450px !important;
    height: auto;
  }
}
