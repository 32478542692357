@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
@import url("https://use.typekit.net/ahj2jxl.css");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Smooch+Sans:wght@100..900&display=swap");
.primary-colour {
  color: #047bc1;
}

.highlight-colour {
  color: #5cb8ef;
}

.secondary-colour {
  color: #00466e;
}

.tertiary-colour {
  color: #72bf44;
}

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.header__logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.section--map .map img, .social a svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer__bottom img {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  scrollbar-gutter: stable;
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 1rem 1.5rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #cacaca;
  cursor: pointer;
}

.custom-select {
  position: relative;
  min-width: 350px;
}
.custom-select select {
  appearance: none;
  /*  safari  */
  -webkit-appearance: none;
  /*  other styles for aesthetics */
  width: 100%;
  padding-right: 6rem;
}
.custom-select::before, .custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: "";
  right: 1rem;
  pointer-events: none;
}
.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid #1c1c1c;
  top: 40%;
}
.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid #1c1c1c;
  top: 55%;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper .ba-form-page {
  padding: 0 !important;
  /*.ba-field-label-wrapper {
    display: none;
  }
  .show-label {
    .ba-field-label-wrapper {
      display: block;
    }
  }*/
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.com-baforms-wrapper .ba-form-page fieldset {
  padding: 0;
  border: none;
}
.com-baforms-wrapper .ba-form-page button::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page input::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page optgroup::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page select::-webkit-input-placeholder,
.com-baforms-wrapper .ba-form-page textarea::-webkit-input-placeholder {
  font-family: "Lexend Deca", sans-serif !important;
  font-weight: 500 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.com-baforms-wrapper .ba-form-page .ba-form-page-navigation-title,
.com-baforms-wrapper .ba-form-page .upload-file-btn,
.com-baforms-wrapper .ba-form-page .upload-file-input,
.com-baforms-wrapper .ba-form-page .ba-field-container select,
.com-baforms-wrapper .ba-form-page .ba-field-container textarea[readonly],
.com-baforms-wrapper .ba-form-page .ba-field-container textarea,
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=text],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=number],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=password],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=email],
.com-baforms-wrapper .ba-form-page .ba-field-container select option,
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: 300;
  font-size: 1rem !important;
  color: #1c1c1c !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #00466e;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #f7f7f7;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Lexend Deca", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  color: #1c1c1c;
  font-weight: 300;
  background: #ffffff;
}

main,
details {
  display: block;
}

a {
  color: #72bf44;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}
a:hover {
  color: #5b9b35;
}

li,
p {
  margin-bottom: 1rem;
}

big,
.big {
  font-size: 1.6rem;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  display: inline-flex;
  position: relative;
  margin: 0.5rem 0 2rem;
  font-family: "halcom", sans-serif;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1;
}
h1.title-style:after, h1[itemprop=headline]:after,
.h1.title-style:after,
.h1[itemprop=headline]:after,
h2.title-style:after,
h2[itemprop=headline]:after,
.h2.title-style:after,
.h2[itemprop=headline]:after,
h3.title-style:after,
h3[itemprop=headline]:after,
.h3.title-style:after,
.h3[itemprop=headline]:after,
h4.title-style:after,
h4[itemprop=headline]:after,
.h4.title-style:after,
.h4[itemprop=headline]:after,
h5.title-style:after,
h5[itemprop=headline]:after,
.h5.title-style:after,
.h5[itemprop=headline]:after,
h6.title-style:after,
h6[itemprop=headline]:after,
.h6.title-style:after,
.h6[itemprop=headline]:after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 40%;
  height: 10px;
  background: rgb(4, 123, 193);
  background: linear-gradient(90deg, rgb(4, 123, 193) 0%, rgb(144, 224, 90) 100%);
}
h1.title-style-lower,
.h1.title-style-lower,
h2.title-style-lower,
.h2.title-style-lower,
h3.title-style-lower,
.h3.title-style-lower,
h4.title-style-lower,
.h4.title-style-lower,
h5.title-style-lower,
.h5.title-style-lower,
h6.title-style-lower,
.h6.title-style-lower {
  font-size: 2rem;
  text-transform: none;
  letter-spacing: 0;
}

h1,
.h1 {
  font-size: 2.4rem;
}
h1--mobile,
.h1--mobile {
  font-size: 2.4rem;
}
h1--tablet,
.h1--tablet {
  font-size: 2.8rem;
}
@media (min-width: 576px) {
  h1,
  .h1 {
    font-size: 2.8rem;
  }
}
@media (min-width: 960px) {
  h1,
  .h1 {
    font-size: 3.2rem;
  }
}

h2,
.h2 {
  font-size: 2.2rem;
}
h2--mobile,
.h2--mobile {
  font-size: 2.2rem;
}
h2--tablet,
.h2--tablet {
  font-size: 2.6rem;
}
@media (min-width: 576px) {
  h2,
  .h2 {
    font-size: 2.6rem;
  }
}
@media (min-width: 960px) {
  h2,
  .h2 {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: 2rem;
}
h3--mobile,
.h3--mobile {
  font-size: 2rem;
}
h3--tablet,
.h3--tablet {
  font-size: 2.4rem;
}
@media (min-width: 576px) {
  h3,
  .h3 {
    font-size: 2.4rem;
  }
}
@media (min-width: 960px) {
  h3,
  .h3 {
    font-size: 2.8rem;
  }
}

h4,
.h4 {
  font-size: 1.8rem;
}
h4--mobile,
.h4--mobile {
  font-size: 1.8rem;
}
h4--tablet,
.h4--tablet {
  font-size: 2.2rem;
}
@media (min-width: 576px) {
  h4,
  .h4 {
    font-size: 2.2rem;
  }
}
@media (min-width: 960px) {
  h4,
  .h4 {
    font-size: 2.6rem;
  }
}

h5,
.h5 {
  font-size: 1.6rem;
}
h5--mobile,
.h5--mobile {
  font-size: 1.6rem;
}
h5--tablet,
.h5--tablet {
  font-size: 2rem;
}
@media (min-width: 576px) {
  h5,
  .h5 {
    font-size: 2rem;
  }
}
@media (min-width: 960px) {
  h5,
  .h5 {
    font-size: 2.4rem;
  }
}

h6,
.h6 {
  font-size: 1.4rem;
}
h6--mobile,
.h6--mobile {
  font-size: 1.4rem;
}
h6--tablet,
.h6--tablet {
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  h6,
  .h6 {
    font-size: 1.8rem;
  }
}
@media (min-width: 960px) {
  h6,
  .h6 {
    font-size: 2.2rem;
  }
}

.subheading {
  font-family: "Lexend Deca", sans-serif;
  text-transform: uppercase;
  color: #047bc1;
  font-weight: bold;
  letter-spacing: 3px;
}
.subheading--2 {
  color: #00466e;
}
.subheading--3 {
  color: #72bf44;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button],
.com-baforms-wrapper .ba-form-submit-btn,
.n2-ss-button-container a {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1rem 1.25rem;
  background: linear-gradient(120deg, #72bf44, #047bc1);
  background-size: 150% 100%;
  background-position: 80% 0;
  border: 2px solid transparent;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: normal;
  font-family: "Lexend Deca", sans-serif;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -ms-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
  -webkit-appearance: none;
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
.com-baforms-wrapper .ba-form-submit-btn:hover,
.n2-ss-button-container a:hover {
  color: #ffffff;
  background-position: 5% 0;
  border: 2px solid transparent;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active,
.com-baforms-wrapper .ba-form-submit-btn:active,
.n2-ss-button-container a:active {
  border: 2px solid transparent;
}
.button:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited,
.com-baforms-wrapper .ba-form-submit-btn:visited,
.n2-ss-button-container a:visited {
  color: #ffffff;
}

.button--large {
  font-size: 0.9rem;
  padding: 1.25rem 1.75rem;
}
.button--full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button--primary {
  background: #047bc1;
}
.button--primary:hover {
  background: #0368a3;
}
.button--secondary {
  background: #00466e;
}
.button--secondary:hover {
  background: #00334f;
}
.button--red {
  background: #d0342c;
}
.button--red:hover {
  background: #b72e27;
}
.button--outline {
  color: #00466e;
  background: transparent;
  border: 2px solid #00466e;
}
.button--outline:hover {
  color: #ffffff;
  background: #047bc1;
  border: 2px solid #047bc1;
}
.button--outline:hover:visited {
  color: #ffffff;
}
.button--outline:active {
  border: 2px solid #00466e;
}
.button--outline:visited {
  color: #00466e;
}
.button--outline-white {
  color: #ffffff !important;
  border-color: #ffffff;
}
.button--outline-white:hover {
  background: #047bc1;
  border: 2px solid #047bc1;
}
.button--outline-white:hover:visited {
  color: #ffffff;
}
.button--outline-secondary {
  color: #72bf44 !important;
  border-color: #72bf44;
}
.button--outline-secondary:hover {
  color: #ffffff !important;
  background: #72bf44;
  border: 2px solid #72bf44;
}
.button--outline-secondary:hover:visited {
  color: #ffffff !important;
}
.button-wrap--center {
  text-align: center;
}
.button--margin {
  margin: 1rem;
}
.button--underline {
  padding: 8px;
  background: transparent;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
}
.button--underline:hover {
  background: transparent;
  color: #047bc1;
  border-bottom-color: #047bc1;
}
.button--underline-2:hover {
  color: #72bf44;
  border-bottom-color: #72bf44;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.n2-ss-slider .n2-ss-button-container a,
.n2-ss-slider .n2-ss-button-container .button {
  display: inline-flex !important;
  flex-direction: column !important;
  padding: 1rem 1.5rem !important;
  background: linear-gradient(120deg, #72bf44, #047bc1) !important;
  background-size: 150% 100% !important;
  background-position: 80% 0 !important;
  border: 2px solid transparent !important;
  color: #ffffff !important;
  font-size: 0.8rem !important;
  font-weight: normal !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  line-height: normal !important;
  font-family: "Lexend Deca", sans-serif !important;
  text-transform: uppercase !important;
  text-align: center !important;
  letter-spacing: 1.5px !important;
  outline: none !important;
  border-radius: 8px !important;
  transition: 500ms ease-in-out !important;
}
.n2-ss-slider .n2-ss-button-container a--underline,
.n2-ss-slider .n2-ss-button-container .button--underline {
  padding: 0.5rem 0.5rem !important;
  border-radius: 0 !important;
  background: transparent !important;
  border-bottom: 2px solid #ffffff !important;
}
.n2-ss-slider .n2-ss-button-container a--underline:hover,
.n2-ss-slider .n2-ss-button-container .button--underline:hover {
  background: transparent !important;
  color: #047bc1 !important;
  border-bottom-color: #047bc1 !important;
}
.n2-ss-slider .n2-ss-button-container a--underline-2:hover,
.n2-ss-slider .n2-ss-button-container .button--underline-2:hover {
  color: #72bf44 !important;
  border-bottom-color: #72bf44 !important;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

@media (min-width: 480px) {
  .hide-smedium {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hide-medium {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-mlarge {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .hide-large {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-xlarge {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .hide-xxlarge {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .hide-xxxlarge {
    display: none !important;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.8.1
 * https://get.foundation
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 36em) {
  .reveal.large, .reveal.small, .reveal.tiny, .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 36em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 112.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 36em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 36em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 36em) and (min-width: 36em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 36em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 64em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 64em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 80em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 80em) and (min-width: 80em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 80em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 90em) {
  .xxxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-1 > .column, .xxxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-2 > .column, .xxxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-3 > .column, .xxxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-4 > .column, .xxxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-5 > .column, .xxxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-6 > .column, .xxxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-7 > .column, .xxxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxxlarge-up-8 > .column, .xxxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 90em) and (min-width: 90em) {
  .xxxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxxlarge-unstack > .column, .row.xxxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 90em) {
  .row.xxxlarge-unstack > .column, .row.xxxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxxlarge-collapse > .column, .xxxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxxlarge-uncollapse > .column, .xxxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 112.5em) {
  .xxxxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxxxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxxxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxxxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxxxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxxxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxxxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxxxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxxxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxxxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxxxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxxxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxxxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxxxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxxxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxxxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxxxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxxxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxxxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxxxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-1 > .column, .xxxxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxxxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-2 > .column, .xxxxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxxxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-3 > .column, .xxxxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxxxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-4 > .column, .xxxxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxxxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-5 > .column, .xxxxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxxxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-6 > .column, .xxxxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxxxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-7 > .column, .xxxxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxxxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxxxlarge-up-8 > .column, .xxxxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 112.5em) and (min-width: 112.5em) {
  .xxxxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxxxlarge-unstack > .column, .row.xxxxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 112.5em) {
  .row.xxxxlarge-unstack > .column, .row.xxxxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxxxlarge-collapse > .column, .xxxxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxxxlarge-uncollapse > .column, .xxxxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none;
}
.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}
.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #047bc1;
}
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}
.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}
.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}
.is-active > .accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #ffffff;
  color: #1c1c1c;
}
:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

html.is-reveal-open {
  position: fixed;
  width: 100%;
  overflow-y: hidden;
}
html.is-reveal-open.zf-has-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
html.is-reveal-open body {
  overflow-y: hidden;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(28, 28, 28, 0.45);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
[data-whatinput=mouse] .reveal {
  outline: 0;
}
@media print, screen and (min-width: 36em) {
  .reveal {
    min-height: 0;
  }
}
.reveal .column, .reveal .columns {
  min-width: 0;
}
.reveal > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .reveal {
    width: 600px;
    max-width: 1440px;
  }
}
.reveal.collapse {
  padding: 0;
}
@media print, screen and (min-width: 36em) {
  .reveal.tiny {
    width: 30%;
    max-width: 1440px;
  }
}
@media print, screen and (min-width: 36em) {
  .reveal.small {
    width: 50%;
    max-width: 1440px;
  }
}
@media print, screen and (min-width: 36em) {
  .reveal.large {
    width: 90%;
    max-width: 1440px;
  }
}
.reveal.full {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  min-height: 100%;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}
@media print, screen and (max-width: 29.99875em) {
  .reveal {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 100%;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}
.reveal.without-overlay {
  position: fixed;
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  list-style-type: none;
}
.tabs::before, .tabs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}
.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #047bc1;
}
.tabs.primary > li > a {
  color: #ffffff;
}
.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #0485d0;
}

.tabs-title {
  float: left;
}
.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #047bc1;
}
[data-whatinput=mouse] .tabs-title > a {
  outline: 0;
}
.tabs-title > a:hover {
  background: #ffffff;
  color: #036aa6;
}
.tabs-title > a:focus, .tabs-title > a[aria-selected=true] {
  background: #e6e6e6;
  color: #047bc1;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #ffffff;
  color: #1c1c1c;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}
.tabs-panel.is-active {
  display: block;
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
/* Main
================================================== */
.body-wrap {
  position: relative;
  overflow: clip;
  -webkit-transform-origin: center top center;
  -moz-transform-origin: center top center;
  -ms-transform-origin: center top center;
  -o-transform-origin: center top center;
  transform-origin: center top center;
  transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.menu-active .body-wrap {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}

.main-content.column, .main-content.columns {
  padding: 0 2rem;
}

main {
  padding: 8rem 0;
}
main.pattern-bg {
  background-image: url("../images/pattern-bg-fade-down.jpg");
  background-size: contain;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
@media (min-width: 960px) {
  main.pattern-bg {
    background-image: url("../images/pattern-bg-fade-right.jpg");
    background-position: 100% 50%;
    background-size: cover;
  }
}
@media (min-width: 1024px) {
  main.pattern-bg {
    background-size: contain;
  }
}

.label-warning {
  display: none;
}

/* Components
================================================== */
/* Rounded cards */
.card {
  width: 100%;
  border-radius: 24px;
  box-shadow: 0 7px 30px -10px #5e717c;
  background-color: white;
}
.card--colour {
  background: #00466e;
  color: white;
}
.card--colour-primary {
  background: #047bc1;
}
.card--colour-primary p {
  color: white !important;
}
.card--small {
  height: 200px;
}
.card__img {
  border-radius: 24px 24px 0 0;
}
.card__img-bottom {
  border-radius: 0 0 24px 24px;
}
.card__text {
  padding: 2rem;
}
.card__subtitle {
  padding: 1rem;
  font-size: 1.6rem;
  text-align: center;
}
.card__button {
  display: flex;
  justify-content: center;
}

/* CTA cards */
.cta-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
@media (min-width: 960px) {
  .cta-container {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
.cta-container a {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
}

.cta {
  width: calc(100% - 2rem);
  height: 300px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 16px;
  overflow: hidden;
  background: #ffffff;
}
.cta:before {
  content: "";
  position: absolute;
  z-index: 20;
  inset: 0;
  background-color: #00466e;
  opacity: 0.3;
}
.cta img {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.cta__text {
  position: absolute;
  z-index: 30;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "halcom", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
}
.cta__text * {
  margin: 0;
}

/* Logo buttons */
.btn-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 7px 20px -10px #cacaca;
}
.btn-logo img {
  display: block;
  max-width: 75%;
  max-height: 75%;
  margin: auto !important;
}
.btn-logo.square {
  max-width: 50%;
}
.btn-logo--slider {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 130px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 7px 20px -10px #cacaca;
}
.btn-logo--slider img {
  display: block;
  max-width: 88%;
  max-height: 88%;
  margin: auto !important;
}
.btn-logo--larger img {
  max-width: 100%;
}
.btn-logo--companies {
  width: 200px !important;
  height: 80px !important;
}
@media (min-width: 576px) {
  .btn-logo--companies {
    width: 300px !important;
    height: 180px !important;
  }
}

.square {
  max-width: 50%;
}

/* Sponsor tags */
.sponsor-tag {
  padding: 0.8rem;
  border-radius: 10px;
  color: #81898d;
}
.sponsor-tag--platinum {
  background-color: #dadfe6;
}

.sidebar-fixed {
  display: none;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(0%);
  z-index: 120;
}
@media (min-width: 1024px) {
  .sidebar-fixed {
    display: block;
  }
}
.sidebar-fixed__wrap p {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar-fixed .button {
  position: absolute;
  width: 240px;
  margin: 0;
  rotate: -90deg;
  background-color: #72bf44;
  color: #ffffff !important;
}
.sidebar-fixed .button:hover {
  background-color: #60a338;
}

/* Social
================================================== */
.social ul {
  margin: 0;
}
.social li {
  position: relative;
  list-style: none;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 8px;
  font-size: 1.4em;
}
.social a {
  display: block;
  height: 100%;
}
.social a:hover svg * {
  fill: #047bc1;
}
.social a svg {
  width: 50%;
  height: 50%;
}
.social a svg * {
  fill: #047bc1;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.sidebar-fixed .social {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 170px;
  background-color: #ffffff;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0 7px 20px -10px #5e717c;
}
.sidebar-fixed .social h3 {
  display: none;
}
.sidebar-fixed .social li {
  width: 44px;
  height: 48px;
  float: right;
  clear: right;
  margin: 0;
  padding: 0;
  text-align: center;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}
.sidebar-fixed .social a {
  display: block;
  margin: 0;
  color: #ffffff;
}
.sidebar-fixed .social a:hover svg * {
  fill: #00466e;
}
footer .social span {
  display: inline-block;
  margin-right: 8px;
}

.team-members .title-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #047bc1;
  color: #ffffff !important;
  margin-bottom: 1rem;
}
.team-members .title-row .row-text {
  padding: 1.9rem;
}
.team-members .spacer {
  display: none;
}
@media (min-width: 576px) {
  .team-members .spacer {
    display: block;
  }
}
.team-members .card__img {
  width: 600px;
}
.team-members .card p {
  margin: 1rem 0;
}
.team-members .card hr {
  background-color: #5cb8ef !important;
  border: none;
  height: 1px;
  width: 70%;
}

#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #5cb8ef;
  opacity: 0.8;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 16px 18px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
  z-index: 96;
}
#scrollToTopBtn svg {
  width: 14px;
}
#scrollToTopBtn svg * {
  fill: #ffffff;
}

#scrollToTopBtn:hover {
  background-color: #047bc1;
}

.ccc-content--dark {
  background-color: #00466e !important;
}

#ccc-icon.ccc-icon--dark {
  fill: #00466e !important;
}

#ccc-icon {
  margin: 0 !important;
}

.accordion {
  padding: 0;
}
.accordion-item {
  margin: 0;
}
.accordion-title {
  border: 0;
  border-bottom: 2px solid #047bc1 !important;
  font-size: 1.1rem;
  font-family: "Lexend Deca", sans-serif;
  color: #00466e;
}
.accordion-title:before {
  margin-top: -0.6rem;
  font-weight: bold;
}
.accordion-content {
  border: 0 !important;
}

.tabs {
  border: 0;
}
.tabs li {
  margin: 0;
}
.tabs a {
  color: #00466e;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.5px;
  border-bottom: 2px solid #cacaca !important;
}
.tabs#faq {
  margin-bottom: 4rem;
}
@media (min-width: 960px) {
  .tabs#faq {
    padding: 0 2rem 2rem 0;
    margin-bottom: 0;
  }
}
.tabs--style-1 .tabs-title > a:focus,
.tabs--style-1 .tabs-title > a[aria-selected=true] {
  background-color: #ffffff !important;
  border-bottom-color: #5cb8ef !important;
}
.tabs--style-1 li {
  width: 100%;
}
@media (min-width: 768px) {
  .tabs--style-1 li {
    width: calc(25% - 1rem);
    margin: 0.5rem;
  }
}
.tabs--style-1 a {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-bottom-width: 4px !important;
}
.tabs--style-1 img {
  object-fit: cover;
  padding: 0.5rem;
}

.tabs-panel {
  padding: 0;
}

.tabs-content {
  padding: 2rem;
  border: 0;
}

/* Header
================================================== */
header {
  position: relative;
  background-color: #ffffff;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 400;
  top: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  transition: 500ms ease-in-out;
}
header .row {
  align-items: center;
  padding: 0.8rem;
  justify-content: space-between;
}
.menu.active header {
  padding: 0;
}
@media (min-width: 1280px) {
  .shrink header .column, .shrink header .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
@media (min-width: 1440px) {
  header .column, header .columns {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
header.shrink, .menu-active header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 99;
  transition: 500ms ease-in-out;
}
@media (min-width: 1280px) {
  header.shrink, .menu-active header {
    position: fixed;
  }
}
header.shrink .header__logo, .menu-active header .header__logo {
  position: relative;
  transition: 500ms ease-in-out;
}
header.shrink .header__logo img, .menu-active header .header__logo img {
  width: 190px;
}
header.shrink .header__logo p, .menu-active header .header__logo p {
  font-size: 1rem;
}
header.shrink .header__logo .vertical-div, .menu-active header .header__logo .vertical-div {
  height: 60px !important;
}
header.shrink .header__logo .conf-info, .menu-active header .header__logo .conf-info {
  position: relative;
}
@media (max-width: 959px) {
  header.shrink .nav--main.active, .menu-active header .nav--main.active {
    top: 4em;
  }
}
header.shrink .header__top {
  padding: 0;
}
.menu-active header .nav--main .button {
  font-size: 1.2rem !important;
}

.header {
  /*&__wrap {
    @extend %flex;
    align-items: center;
    justify-content: center;

    @include bp(xlarge) {
      justify-content: space-around;
    }
  }*/
}
.header__top {
  z-index: 90;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
@media (min-width: 1024px) {
  .header__top {
    flex-direction: row;
  }
}
.header__logo {
  position: relative;
  z-index: 70;
  justify-content: center;
  align-items: center;
  transition: 500ms ease-in-out;
  padding: 0.5rem 1rem;
}
.header__logo p {
  margin: 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1.7rem;
}
@media (min-width: 576px) {
  .header__logo p {
    font-size: 0.9rem;
  }
}
@media (min-width: 768px) {
  .header__logo p {
    font-size: 1rem;
  }
}
@media (min-width: 1024px) {
  .header__logo p {
    font-size: 0.9rem;
  }
}
@media (min-width: 1280px) {
  .header__logo p {
    font-size: 1rem;
  }
}
.header__logo p:first-of-type {
  margin-right: 0.5rem;
}
.header__logo a {
  display: block;
  transition: 500ms ease-in-out;
}
.header__logo img {
  display: block;
  width: 240px;
  padding: 0 0 1rem 0;
}
@media (min-width: 576px) {
  .header__logo img {
    width: 150px;
    padding: 0;
  }
}
@media (min-width: 1280px) {
  .header__logo img {
    width: 270px;
  }
}
.header__logo .conf-info .paragraphs {
  display: inline-block;
  vertical-align: middle;
}
.header__logo .conf-info::before {
  display: none;
}
@media (min-width: 576px) {
  .header__logo .conf-info::before {
    content: " ";
    display: inline-block;
    height: 80px;
    width: 8px;
    background: rgb(4, 123, 193);
    background: linear-gradient(180deg, rgb(4, 123, 193) 0%, rgb(144, 224, 90) 100%);
    margin: 0 10px;
    vertical-align: middle;
  }
}
.header__right {
  padding: 14px 0;
  width: 100%;
}
@media (min-width: 1024px) {
  .header__right {
    width: auto;
    text-align: right;
  }
}
@media (max-width: 959px) {
  .header__right .button--stand,
  .header__right .button--int {
    display: none;
  }
}
.header__right p,
.header__right a {
  margin: 0;
}
.header .social {
  margin: 0 2rem 0 0;
}
.header__buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /*@include bp(xxlarge) {
    margin-top: 2.25rem;
  }*/
}
@media (min-width: 576px) {
  .header__buttons {
    flex-direction: row;
  }
}
.header__buttons .custom {
  display: flex;
}
.header__buttons .button {
  margin: 0.5rem;
}
@media (min-width: 576px) {
  .header__buttons .button {
    margin: 0 0.5rem;
  }
}
.header__buttons .button--select {
  display: none;
}
@media (min-width: 960px) {
  .header__buttons .button--select {
    display: inline-flex;
  }
}
@media (min-width: 1024px) {
  .header__buttons .button--select {
    display: none;
  }
}
.header__events-list {
  display: none;
}
@media (min-width: 1024px) {
  .header__events-list {
    display: block;
    position: relative;
    z-index: 80;
  }
}
@media (min-width: 1280px) {
  .header__events-list {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.header__events-list .events-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(218, 218, 218) 100%);
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.header__events-list .events-list li {
  position: relative;
  padding: 0.5rem;
  margin: 0 1rem;
  min-width: 230px;
}
.header__events-list .events-list li:hover .events-list__link {
  display: flex;
}
.header__events-list .events-list__title {
  margin: 0;
  font-family: "Smooch Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  text-align: center;
  cursor: pointer;
}
.header__events-list .events-list__link {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
.header__events-list .events-list__link.active {
  display: flex;
}
.header__events-list .events-list__link img {
  height: 70px;
}
.header__events-list .events-list__link .button {
  margin: 1rem 0 0 0;
}

.vertical-div {
  height: 80px;
  width: 8px;
  background: rgb(4, 123, 193);
  background: linear-gradient(180deg, rgb(4, 123, 193) 0%, rgb(144, 224, 90) 100%);
  margin: 0 10px;
}

/* Navigation
================================================== */
body.menu-active {
  overflow: hidden;
}

.nav {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.nav li {
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  position: relative;
  margin: 0;
  text-align: center;
  list-style: none;
}
.nav a {
  display: block;
  padding: 8px;
  color: #ffffff;
  font-size: 1em;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}
.nav a:hover,
.nav .current > a,
.nav .active > a {
  color: #5cb8ef;
}
.nav ul {
  display: none;
  width: 100%;
  position: static;
  margin: 0;
  font-size: 0.8em;
}
@media (min-width: 960px) {
  .nav ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    min-width: 100%;
    width: auto;
    font-size: 1em;
  }
}
@media (max-width: 959px) {
  .nav ul.active {
    display: block;
  }
}
.nav ul li {
  width: 100%;
}
@media (min-width: 960px) {
  .nav li:hover > ul {
    display: block;
  }
}
.nav > * {
  overflow: visable;
}
.nav .item-415,
.nav .item-495 {
  max-width: 80px;
}
.nav .home-link img {
  width: 24px;
}
.nav .home-link .image-title {
  display: none;
}
.nav svg {
  display: inline-block;
  width: 6px;
  height: 12px;
  margin-left: 6px;
  fill: #ffffff;
}
@media (min-width: 1024px) {
  .nav svg {
    width: 10px;
  }
}

/* Main Navigation
================================================== */
.nav--main {
  display: block;
}
@media (max-width: 479px) {
  .nav--main {
    position: relative !important;
  }
}
.nav--main.shrink {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 90;
  box-shadow: 0px 6px 14px 0px rgba(79, 77, 87, 0.4);
}
.nav--main .nav__outer {
  display: flex;
  flex-grow: 1;
  background-color: #00466e;
  box-shadow: 0 2px 11px 3px rgba(0, 0, 0, 0.2);
}
@media (max-width: 959px) {
  .nav--main .nav__outer {
    flex-direction: row-reverse;
  }
}
.nav--main .row {
  width: 100%;
}
@media (min-width: 960px) {
  .nav--main .row {
    padding: 0;
  }
}
@media (max-width: 959px) {
  .nav--main .column, .nav--main .columns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (max-width: 959px) {
  .nav--main .nav__wrap {
    display: none;
  }
}
.nav--main .moduletable {
  width: 100%;
}
.nav--main .nav,
.nav--main a {
  height: 100%;
}
@media (max-width: 959px) {
  .nav--main .nav {
    min-height: 100vh;
    overflow: auto;
  }
}
.nav--main a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1rem;
  color: #ffffff;
}
@media (min-width: 960px) {
  .nav--main a {
    font-size: 0.8em;
  }
}
@media (min-width: 1024px) {
  .nav--main a {
    font-size: 0.9em;
  }
}
.shrink .nav--main a {
  padding: 0.8rem;
}
.nav--main a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 6px;
  margin: auto;
  background: #5cb8ef;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
}
.nav--main a:hover,
.nav--main .current > a,
.nav--main .active > a {
  color: #5cb8ef;
}
.nav--main a:hover:after,
.nav--main .current > a:after,
.nav--main .active > a:after {
  width: 100%;
}
@media (min-width: 960px) {
  .nav--main .button--select {
    display: none;
  }
}
.nav--main .button--outline {
  border-color: #ffffff;
}
.nav--main .button--outline:hover {
  color: #ffffff;
  border-color: #047bc1;
}
.nav--main .button--outline:visited {
  color: #ffffff;
}
.nav--main .button--outline:after {
  display: none;
}

/*.dca, .current > .dca, .active > .dca {
    &:after {
      background: @tertiary_colour;
    } 
  } */
.nav ul {
  background-color: #00466e;
  box-shadow: 0px 6px 14px 0px rgba(79, 77, 87, 0.4);
  font-size: 0.8em;
}
.nav ul a {
  color: #ffffff;
}

/* Overlay Navigation
================================================== */
.nav--overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 0;
  margin: auto;
  overflow: hidden;
  background-color: #ffffff;
}
.nav--overlay.active {
  height: 100%;
  overflow: initial;
}
.nav--overlay .nav__wrap {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.nav--overlay .nav__wrap .header__buttons .button--select {
  display: none;
}
.nav--overlay .vertical-align {
  display: block;
  width: 100%;
  height: auto;
  z-index: 5;
}
.nav--overlay .columns {
  transform: translateY(-300%);
  -webkit-transform: translateY(-300%);
  -moz-transform: translateY(-300%);
  -ms-transform: translateY(-300%);
  -o-transform: translateY(-300%);
  transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -ms-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: transform 0s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}
.menu-active .nav--overlay .columns {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  opacity: 1;
}
.nav--overlay .moduletable,
.nav--overlay .switch-show {
  transform: translateY(40px);
  -webkit-transform: translateY(40px);
  -moz-transform: translateY(40px);
  -ms-transform: translateY(40px);
  -o-transform: translateY(40px);
  transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: opacity 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}
.menu-active .nav--overlay .moduletable,
.menu-active .nav--overlay .switch-show {
  animation: fadeInUp 1s 0.5s forwards;
  -webkit-animation: fadeInUp 1s 0.5s forwards;
  -moz-animation: fadeInUp 1s 0.5s forwards;
  -ms-animation: fadeInUp 1s 0.5s forwards;
}
.nav--overlay .nav {
  display: flex;
  flex-direction: column;
}
.nav--overlay .nav > li {
  margin-bottom: 24px;
  text-align: center;
  /* @include bp_max(smedium) {
        	width: 33.3%;
        }
        @include bp(medium) {
        	width: 16.6%;
        } */
}
.nav--overlay .nav a {
  font-size: 1.2em;
  color: #047bc1;
}
.nav--overlay .nav a:hover {
  color: #00466e;
}
.nav--overlay .nav ul {
  background-color: transparent;
  box-shadow: none;
  position: static;
  width: 100%;
  display: block;
  margin: 0;
  font-size: 0.7em;
}
.nav--overlay .nav ul li {
  margin: 0;
}
.nav--overlay .nav ul a {
  padding: 8px 11px;
  font-size: 1em;
}
.nav--overlay .nav .button {
  padding: 14px 0;
}
.nav--overlay svg {
  display: none;
}
.nav--overlay .register-buttons {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
  text-align: center;
}
.nav--overlay .register-buttons > div {
  justify-content: center;
}
.nav--overlay .register-buttons a {
  max-width: 320px;
  width: 100%;
  margin: 0 0 24px 0;
}
@media (min-width: 480px) {
  .nav--overlay .register-buttons a {
    width: 48%;
    margin: 1%;
  }
}
.nav--overlay .menu-link {
  margin: 0 auto;
  background: transparent;
}
.nav--overlay .menu-link p {
  display: inline-block;
  color: #047bc1;
}
.nav--overlay .menu-link span,
.nav--overlay .menu-link span:before,
.nav--overlay .menu-link span:after {
  background-color: #047bc1;
}
.nav--overlay .social p {
  display: block;
}
.nav--overlay .switch-show a {
  background-color: #ffffff !important;
}
@media (max-width: 479px) {
  .nav--overlay .switch-show__logo {
    width: 140px;
  }
}
.nav--overlay .switch-show__icon {
  display: none;
}

/* 404 Page Navigation
================================================== */
.error-page .nav {
  position: static;
}
.error-page .nav svg {
  display: none;
}
.error-page .nav a {
  padding: 8px;
  color: #047bc1;
}
.error-page .nav a:hover {
  color: #00466e;
}
.error-page .nav li,
.error-page .nav ul {
  position: static;
  min-width: 100%;
  margin: 0;
  display: block;
  text-align: center;
  background: transparent;
}
.error-page .nav ul {
  margin-bottom: 24px;
  font-size: 0.9em;
}

/* Hamburger
================================================== */
.menu-link {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-width: 110px;
  margin: 0;
  padding: 20px 16px;
  outline: none;
  background: transparent !important;
}
@media (min-width: 960px) {
  .menu-link {
    display: none;
  }
}
.menu-link p {
  margin: 0 36px 0 0;
  color: #ffffff;
}
.menu-link span,
.menu-link span:before,
.menu-link span:after {
  content: "";
  position: absolute;
  top: 46%;
  right: 0;
  display: inline-block;
  width: 24px;
  height: 3px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 1px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.menu-link span {
  right: 1rem;
}
.menu-link span:before {
  top: -8px;
}
.menu-link span:after {
  top: auto;
  bottom: -8px;
}
.menu-link.active span {
  background: transparent;
}
.menu-link.active span:before, .menu-link.active span:after {
  top: 0;
}
.menu-link.active span:before {
  rotate: 45deg;
}
.menu-link.active span:after {
  rotate: -45deg;
}

/* Select Event / Sidebar Navigation
================================================== */
.nav--sidebar {
  position: fixed;
  top: -20px;
  right: 0;
  bottom: -20px;
  z-index: 130;
  overflow-y: scroll;
  width: 320px;
  padding: 40px 0;
  text-align: center;
  -webkit-transform: translateX(320px);
  transform: translateX(320px);
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  background: rgb(4, 123, 193);
  background: linear-gradient(180deg, rgb(4, 123, 193) 0%, rgb(144, 224, 90) 100%);
  -webkit-box-shadow: inset 0px 0px 24px 0px rgba(60, 60, 59, 0.5);
  -moz-box-shadow: inset 0px 0px 24px 0px rgba(60, 60, 59, 0.5);
  box-shadow: inset 0px 0px 24px 0px rgba(60, 60, 59, 0.5);
}
.sidebar-menu-active .nav--sidebar {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.nav--sidebar h3,
.nav--sidebar p {
  margin: 0;
  padding: 16px 40px;
}
.nav--sidebar h5 {
  margin: 0 1rem 1rem;
  font-size: 1.4rem;
  color: #ffffff;
}
.nav--sidebar p {
  display: block;
}
.nav--sidebar a {
  display: block;
  width: 100%;
}
.nav--sidebar img {
  width: 100%;
}
.nav--sidebar .close-button {
  display: block !important;
  height: 30px;
  margin-top: 0.5rem;
  background: none;
  cursor: pointer;
}
.nav--sidebar .close-button::before {
  content: "✕";
  font-size: 1.6rem;
  line-height: 1rem;
}

/* Animation
================================================== */
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*body{
  height:300vh;
  background: linear-gradient(45deg,  violet , dodgerblue, hotpink);
}*/
.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.popup-overlay .popup {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 700px;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media (max-width: -1) {
  .popup-overlay .popup {
    font-size: 0.8em;
  }
}
.popup-overlay .popup--wide {
  max-width: 1024px;
}
.popup-overlay .title {
  margin-bottom: 8px;
}
@media (max-width: 479px) {
  .popup-overlay .title {
    font-size: 1.4em;
  }
}
.popup-overlay .title:after {
  display: none;
}
.popup-overlay h4 {
  width: 100%;
}
.popup-overlay p {
  color: #1c1c1c;
}
.popup-overlay .button {
  margin-bottom: 0;
}
.popup-overlay .close {
  position: absolute;
  top: 1em;
  right: 1em;
  height: auto;
  margin: 0;
  padding: 0.25rem 1rem;
  line-height: 100%;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-family: sans-serif;
}
.popup-overlay .close:after {
  display: none;
}

/* Banner / Slider
================================================== */
.banner {
  position: relative;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75% 50%;
  /*background-image: url('../images/GCAKV--longbgbanner.webp');
  background-color: rgba($dark-blue, 0.6);

  &:before {
    content: '';
    background-color: rgba($dark-blue, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include bp(xlarge) {
      display: none;
    }
  }*/
}
.banner .row {
  width: 100%;
}
.banner__content {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.banner__text {
  padding: 8rem 1rem 10rem;
  z-index: 2;
}
@media (min-width: 768px) {
  .banner__text {
    padding: 14rem 1rem 16rem;
  }
}
.banner__text * {
  color: #ffffff;
  text-shadow: 0 0 16px rgba(0, 70, 110, 0.5);
}
.banner__text h1 {
  margin: 0 0 1rem 0;
  max-width: 1024px;
}
@media (max-width: 575px) {
  .banner__text h1 {
    font-size: 2rem;
  }
}
@media (max-width: 575px) {
  .banner__text .subheading {
    font-size: 0.8rem;
  }
}
.banner__text-details {
  display: none;
  margin: 3rem 0 1rem 0;
}
.banner__text-details h6 {
  margin: 0 !important;
  text-transform: none;
  line-height: 1.2;
}
.banner__text-details p {
  margin: 0;
}
.banner__text .button-wrap * {
  text-shadow: none;
}
.banner__text .button-wrap p,
.banner__text .button-wrap a {
  margin: 0;
}
.banner__text .button-wrap .button {
  margin-top: 1rem;
  font-size: 0.9rem;
  padding: 1.25rem 1.75rem;
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
}
.banner__text .button-wrap .button:hover {
  color: #ffffff;
  background: #047bc1;
  border: 2px solid #047bc1;
}
.banner__text .button-wrap .button:hover:visited {
  color: #ffffff;
}
@media (max-width: 767px) {
  .banner__text .button-wrap .button {
    background: linear-gradient(120deg, #72bf44, #047bc1);
    background-size: 150% 100%;
    background-position: 80% 0;
    border: none !important;
  }
  .banner__text .button-wrap .button:hover {
    background: linear-gradient(120deg, #72bf44, #047bc1);
    background-size: 150% 100%;
    background-position: 5% 0;
  }
}
.banner__text .co-located {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 960px) {
  .banner__text .co-located {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.banner__text .co-located .btn-logo {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .banner__text .co-located .btn-logo {
    margin-right: 2rem;
  }
}
.banner__text .co-located img {
  margin: 2rem 0;
  height: 100px;
}
.banner__text .event-logo--white {
  height: 100px;
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .banner__text .event-logo--white {
    margin: 2rem 0;
  }
}
.banner__text .event-logo--white img {
  width: 400px;
  height: auto;
}
.banner--video .n2-ss-slider,
.banner--video .n2-ss-slider-1,
.banner--video .n2-ss-slider-3 {
  display: block !important;
}
.banner__video {
  position: absolute;
  z-index: 10;
  inset: 0;
  height: 100%;
}
.banner__video * {
  height: 100% !important;
}
.banner__video ss3-force-full-width {
  transform: translate3d(0, 0px, 0px) !important;
  width: 100% !important;
}
.banner__video .n2-ss-slide-background-video {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  left: auto !important;
  height: 115% !important;
  margin: auto !important;
}
@media (max-width: 575px) {
  .banner__video .n2-ss-slide-background-video {
    object-position: 70% 50%;
  }
}
.banner-mha {
  background-image: url("../images/MHA KV-cleaner--longbgbanner.webp");
}
.banner-mha .banner-text h1 {
  margin: 0;
  font-size: 3.5rem !important;
}
@media (min-width: 576px) {
  .banner-mha .banner-text h1 {
    font-size: 3.2rem;
  }
}
@media (min-width: 1024px) {
  .banner-mha .banner-text h1,
  .banner-mha .banner-text h4 {
    line-height: 1.3;
  }
}
.banner-mha .banner-text .co-located .event-logo--white-mha {
  height: 100px;
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .banner-mha .banner-text .co-located .event-logo--white-mha {
    margin: 2rem 0;
  }
}
.banner-mha .banner-text .co-located .event-logo--white-mha img {
  width: 350px !important;
  height: auto;
}
.banner-dca {
  background-image: url("../images/DCA-longbgbanner-01.webp");
  /*:before {
    content: '';
    background-color: rgba($dark-grey, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include bp(xlarge) {
      display: none !important;
    }
  }*/
}
.banner-dca .banner-text h1 {
  line-height: 1;
  margin: 0;
  font-size: 3.5rem !important;
  text-align: center;
}
@media (min-width: 576px) {
  .banner-dca .banner-text h1 {
    text-align: left;
    font-size: 3.2rem;
  }
}
.banner-dca .banner-text .co-located {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 960px) {
  .banner-dca .banner-text .co-located {
    flex-direction: row;
    justify-content: flex-start;
  }
}
.banner-dca .banner-text .co-located .png-img {
  height: auto !important;
}

/* Inner banner
================================================== */
.banner--inner .banner__text {
  padding: 5rem 1rem !important;
}

/*.banner-inner {
  background-image: url('../images/GCAKV--longbgbanner.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  //height: 550px;

  @include bp(medium) {
    height: 410px;
  }

  @include bp(xxxlarge) {
    background-image: url('../images/GCA-KV-innerbanner.png');
  }

  .banner-text {
    //background-color: rgba($dark-blue, 0.6);
    padding: 0.5rem 0.5rem;
    margin-top: 1rem;

    @include bp(large) {
      margin: 0;
      background: none;
      padding: 2rem 1rem;
    }

    h1,
    h4,
    h6 {
      color: $white;
    }
    h1 {
      line-height: 1;
      margin: 0;
    }

    h4 {
      font-size: 2rem;
      text-transform: capitalize;
      margin: 0 !important;
      letter-spacing: 3px;

      @include bp(large) {
        font-size: $base-h4-font-size;
      }
    }

    h6 {
      text-transform: none;
      @include bp(large) {
        line-height: 0.5;
      }
    }

    .event-logo--white {
      height: 100px;
      margin: 1.5rem 0;

      @include bp(smedium) {
        margin: 0rem 0 3rem;
      }

      @include bp(medium) {
        margin: 0rem 0 3rem;
      }

      img {
        width: 400px;
        height: auto;
      }
    }
  }
}*/
.event-logo--white-mha {
  height: 100px;
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .event-logo--white-mha {
    margin: 2rem 0;
  }
}
.event-logo--white-mha img {
  width: 350px !important;
  height: auto;
}

.event-logo--white-dca {
  height: 150px;
}
.event-logo--white-dca img {
  width: 450px !important;
  height: auto;
}

/* Page Specific Styling
================================================== */
.venue-image {
  background-image: url("../images/Marina_Bay_Sands_Property_Overview_Shot_3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
@media (max-width: 959px) {
  .venue-image {
    height: 600px;
  }
}

.venue-text {
  padding: 6rem 2rem;
  /*@include bp(xxxlarge) {
      margin: 0 0 0 13rem;
  }*/
}

.venue-sub {
  position: absolute;
  bottom: 0;
  right: 0;
}
.venue-sub li {
  padding: 1.2rem 5rem 1.2rem 1rem !important;
}

@media (min-width: 960px) {
  .singapore-info {
    padding: 0rem 5rem 5rem 5rem;
  }
}

.header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 0;
}
.header-text h4 {
  font-size: 2.5rem;
}
.header-text h5 {
  font-size: 1.7rem;
}

.trimble-table table {
  border: none !important;
  font-size: 0.9rem;
}
@media (min-width: 480px) {
  .trimble-table table {
    font-size: inherit;
  }
}
.trimble-table th {
  background: none;
  font-size: 1.5rem;
  color: #5cb8ef;
}
.trimble-table tr {
  border: none;
}
.trimble-table td {
  border-bottom: none;
}
.trimble-table td:nth-child(1) {
  border-right: 4px solid #5cb8ef;
}
.trimble-table li {
  list-style: circle;
  margin: 0 0.1rem 0 0.25rem;
  padding: 0.5rem 0;
}
@media (min-width: 576px) {
  .trimble-table li {
    margin: 0 2rem 0 2rem;
    padding: 0.5rem;
  }
}

/* Sections 
================================================== */
section,
.section {
  position: relative;
  overflow: hidden;
  padding: 6rem 0;
}

.section--colour {
  background-color: #047bc1;
}
.section--colour-darker {
  background-color: #00466e;
}
.section--whitebdy {
  color: #ffffff;
}
.section--whitebdy h2 {
  color: #ffffff;
}
.section--narrow {
  padding: 3rem;
}
.section--text-center {
  text-align: center;
}
.section--pattern-bg-right {
  background-image: url("../images/pattern-bg-fade-down.jpg");
  background-size: contain;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
@media (min-width: 960px) {
  .section--pattern-bg-right {
    background-image: url("../images/pattern-bg-fade-right.jpg");
    background-position: 100% 50%;
    background-size: cover;
  }
}
@media (min-width: 1024px) {
  .section--pattern-bg-right {
    background-size: contain;
  }
}
.section--pattern-bg-bottom {
  background-image: url("../images/pattern-bg-fade-down.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.section--gradient {
  background: hsl(202, 67%, 45%);
  background: linear-gradient(hsl(202, 67%, 45%) 0%, rgb(43, 190, 202) 53%, hsl(0, 0%, 100%) 100%);
  background: -moz-linear-gradient(hsl(202, 67%, 45%) 0%, rgb(43, 190, 202) 53%, hsl(0, 0%, 100%) 100%);
  background: -webkit-linear-gradient(hsl(202, 67%, 45%) 0%, rgb(43, 190, 202) 53%, hsl(0, 0%, 100%) 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
.section--intro {
  padding: 0 2rem 5rem 2rem !important;
}
.section--testimonial {
  margin: 5rem 0;
}
.section--lightbg {
  background-color: #f2fbfc;
}
.section--fancylist ul {
  list-style: none;
}
.section--fancylist li {
  color: #5e717c;
  padding: 0.4rem 1rem;
  margin: 1rem 0;
  border-left: 4px solid #047bc1;
  background-color: #f2f6fa;
}
.section--fancylist-secondary li {
  border-left: 4px solid #00466e;
}
.section--fancylist-lightbg li {
  color: #5e717c;
  padding: 0.4rem 1rem;
  margin: 1rem 0;
  background-color: #ffffff;
}
.section--cta {
  padding-bottom: 0;
}
.section--map {
  /* {
    @include bp_max(large) {
  	text-align: center;
    }
  } */
}
.section--map p {
  color: #1c1c1c;
}
@media (max-width: 959px) {
  .section--map .map {
    margin-bottom: 2em;
  }
}
.section--map .map__wrap {
  position: relative;
  min-height: 320px;
  height: 100%;
  background: url("../images/map-bg.jpg");
  background-size: cover;
}
.section--map .map img {
  width: 120px;
}
.section--map .mapDetails .card {
  margin: 0;
}
.section--map .mapDetails .right {
  text-align: center;
}
@media (min-width: 480px) {
  .section--map .mapDetails .right {
    text-align: right;
  }
}
.section--map .mapDetails img {
  width: 240px;
}
.section--map .mapDetails__travel {
  margin-bottom: 2em;
}

@media (min-width: 960px) {
  .button-align-right {
    text-align: right;
  }
}

/* Countdown
================================================== */
.section--countdown {
  padding: 3rem 0;
}
.section--countdown h3,
.section--countdown h5,
.section--countdown h6 {
  margin: 0.5rem 0;
}
.section--countdown h5,
.section--countdown h6 {
  font-size: 1.1rem;
}
@media (min-width: 576px) {
  .section--countdown h5,
  .section--countdown h6 {
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) {
  .section--countdown h5,
  .section--countdown h6 {
    font-size: 1.4rem;
  }
}
@media (min-width: 1024px) {
  .section--countdown h5,
  .section--countdown h6 {
    font-size: 1.7rem;
  }
}
.section--countdown h5 {
  color: #ffffff;
}
.section--countdown h6 {
  color: #5cb8ef;
}
.section--countdown img {
  height: 90px;
}
.section--countdown .button {
  margin: 1rem 0 0 0;
}

.countdown {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 2.3rem;
  flex-direction: row;
}
@media (min-width: 960px) {
  .countdown {
    font-size: 2.6rem;
  }
}
.countdown div {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.countdown__event {
  display: flex;
  justify-content: center;
}
@media (min-width: 960px) {
  .countdown__event {
    padding-right: 1.5rem;
  }
}
.countdown__logo-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.countdown__number {
  min-width: 80px;
  margin-bottom: 0.5rem;
  line-height: 100%;
  color: #ffffff;
  font-weight: 600;
  font-family: "europa", sans-serif;
  font-size: 2.8rem;
}
.countdown__text {
  color: #ffffff;
  font-size: 0.3em;
  text-transform: uppercase;
  opacity: 0.5;
}
.countdown__colon {
  width: 40px;
  color: white;
  margin-top: 0.4rem;
  font-size: 1.5rem;
}

/* Sliders
================================================== */
.n2-section-smartslider .n2-bullet,
.n2-section-smartslider .nextend-arrow {
  transition: 500ms ease-in-out !important;
}

.slider--colocated {
  background: #047bc1;
}

.testimonial .quote-mark {
  transform: none !important;
  left: 0 !important;
  right: 0;
  width: 100% !important;
  height: auto !important;
  color: #72bf44 !important;
  font-family: "Smooch Sans", sans-serif !important;
  font-size: 180px !important;
}
.testimonial .quote-mark h2 {
  line-height: 1.2 !important;
}
.testimonial__details {
  bottom: -10px;
  top: auto !important;
  transform: none !important;
  max-width: 90%;
}
@media (min-width: 768px) {
  .testimonial__details {
    max-width: 60%;
    bottom: -2rem;
  }
}
.testimonial__details > div {
  border-left: 6px solid #72bf44 !important;
}
.testimonial__details .n2-ss-layer-row-inner {
  flex-wrap: nowrap !important;
}
.testimonial__logo {
  width: 60px !important;
  height: 60px !important;
}
.testimonial__logo * {
  height: 100% !important;
}
.testimonial__logo picture {
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #00466e;
  box-sizing: border-box !important;
  padding: 4px !important;
}
.testimonial__logo img {
  object-fit: contain;
}

@media (max-width: 1023px) {
  .announcements .n2-ss-section-main-content {
    flex-direction: column-reverse !important;
  }
}
.announcements__content {
  position: relative !important;
  z-index: 20 !important;
  display: flex !important;
  align-items: center !important;
  width: calc(100% - 4rem) !important;
  height: 100% !important;
  margin-right: auto !important;
  padding: 6rem 2rem !important;
  background-color: #00466e;
  border-right: 4px solid #00466e;
}
@media (min-width: 1024px) {
  .announcements__content {
    width: calc(40% - 2rem) !important;
    height: 100% !important;
    padding-right: 0 !important;
    padding-bottom: 8rem !important;
  }
}
.announcements__content .subheading {
  margin-bottom: 0.5rem !important;
  font-family: "Lexend Deca", sans-serif !important;
  text-transform: uppercase !important;
  color: #72bf44 !important;
  font-weight: bold !important;
  letter-spacing: 3px !important;
  font-size: 1.2rem !important;
}
.announcements h1,
.announcements h2,
.announcements h3,
.announcements h4,
.announcements h5,
.announcements h6, .announcements__title {
  font-family: "halcom", sans-serif !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}
.announcements h1 h2,
.announcements h2 h2,
.announcements h3 h2,
.announcements h4 h2,
.announcements h5 h2,
.announcements h6 h2, .announcements__title h2 {
  font-size: 2.4rem !important;
}
@media (min-width: 576px) {
  .announcements h1 h2,
  .announcements h2 h2,
  .announcements h3 h2,
  .announcements h4 h2,
  .announcements h5 h2,
  .announcements h6 h2, .announcements__title h2 {
    font-size: 2.8rem !important;
  }
}
@media (min-width: 960px) {
  .announcements h1 h2,
  .announcements h2 h2,
  .announcements h3 h2,
  .announcements h4 h2,
  .announcements h5 h2,
  .announcements h6 h2, .announcements__title h2 {
    font-size: 3.2rem !important;
  }
}
.announcements__shape {
  display: none;
  position: absolute !important;
  z-index: 30 !important;
  left: 40%;
  top: 0;
  bottom: 0;
  width: 5rem;
  height: 100%;
  background: url("../images/triangle-blue-right.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}
@media (min-width: 1024px) {
  .announcements__shape {
    display: block;
  }
}
.announcements__bg {
  z-index: 10 !important;
  width: 60% !important;
  height: 100% !important;
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  background-color: #ffffff;
}
@media (max-width: 1023px) {
  .announcements__bg {
    position: static !important;
    width: 100% !important;
    max-height: 400px;
  }
}
.announcements__bg * {
  width: 100%;
  height: 100% !important;
}
.announcements__bg img {
  object-fit: cover;
}
@media (min-width: 1024px) {
  .announcements__bg img {
    object-position: 100% 50%;
  }
}
@media (min-width: 1440px) {
  .announcements__bg img {
    object-position: 80% 50%;
  }
}
.announcements__bg--contain img {
  object-fit: contain;
}
.announcements .button {
  margin-bottom: 0 !important;
}
.announcements .nextend-arrow {
  opacity: 0.5 !important;
}
.announcements .nextend-arrow:hover {
  opacity: 1 !important;
}
@media (min-width: 1024px) {
  .announcements-slide--2 .announcements__bg img {
    object-position: 100% 50%;
  }
}
@media (min-width: 1440px) {
  .announcements-slide--2 .announcements__bg img {
    object-position: 80% 50%;
  }
}

/* Stats
================================================== */
.stats .row {
  padding: 1rem 0;
}
.stats ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  transition: 500ms ease-in-out;
}
@media (max-width: 479px) {
  .stats ul {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }
}
@media (min-width: 768px) {
  .stats ul {
    margin-left: 4rem;
  }
}
.stats .stat {
  width: 100%;
  list-style: none;
  margin: 1rem 0;
  padding: 0 1.5rem;
}
@media (min-width: 480px) {
  .stats .stat {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.stats .stat__number {
  display: flex;
  align-items: center;
}
.stats p {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-family: "Lexend Deca", sans-serif;
  text-transform: uppercase;
}
.stats strong {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  font-family: "Smooch Sans", sans-serif;
}
.stats img {
  display: block;
}
.stats svg {
  width: 56px;
  min-width: 56px;
  margin-right: 1.5rem;
}
.stats svg * {
  fill: #1c1c1c;
}
.stats--about .row {
  padding: 1rem 0;
}
.stats--about ul {
  display: flex;
  justify-content: space-evenly;
  background-color: #047bc1;
  border-radius: 24px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  transition: 500ms ease-in-out;
}
@media (max-width: 767px) {
  .stats--about ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 1024px) {
  .stats--about ul {
    flex-direction: column;
    background-color: transparent;
  }
}
.stats--about .stat {
  width: 100%;
  list-style: none;
  margin: 0.5rem 0;
  padding: 0 1.5rem;
}
@media (min-width: 1024px) {
  .stats--about .stat {
    background-color: #047bc1;
    border-radius: 100%;
    width: 250px;
    height: 250px;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .stats--about .stat {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 479px) {
  .stats--about .stat {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.stats--about p {
  text-align: center;
  margin: 0.5rem 0;
  font-size: 1.4rem;
  color: #ffffff;
  padding: 1rem;
}
.stats--about strong {
  display: block;
  font-size: 3rem;
  font-weight: 700;
  font-family: "halcom", sans-serif;
}
.stats--about img {
  display: block;
}
.stats--about img svg {
  fill: white;
}

#register-interest {
  padding: 0;
}

/* News & Partners
================================================== */
.latest-news:hover .tns-nav {
  opacity: 1;
}
.latest-news ul.latestnews-items {
  flex-flow: column !important;
  justify-content: normal !important;
  align-items: normal !important;
  flex-wrap: wrap !important;
  overflow: visible !important;
}
@media (min-width: 960px) {
  .latest-news ul.latestnews-items {
    flex-flow: row !important;
    flex-wrap: wrap !important;
  }
}
.latest-news .latestnews-item {
  flex: 1 1 0px;
  min-width: 0;
  max-width: 100%;
  margin-bottom: 2rem !important;
  padding: 0 0.9375rem 2rem !important;
}
@media (min-width: 960px) {
  .latest-news .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    margin-bottom: 0rem !important;
  }
}
.latest-news .latestnews-item::before {
  display: none;
}
.latest-news .latestnews-item > a {
  display: block;
  height: 100%;
}
.latest-news .latestnews-item > a:hover strong {
  margin-left: 0.5rem;
}
.latest-news .latestnews-item > a:hover .link a {
  background-position: 5% 0;
}
.latest-news .latestnews-item a {
  color: #1c1c1c;
}
.newsSection .latest-news .latestnews-item {
  border: 0;
}
.latest-news .latestnews-item.active {
  opacity: 1 !important;
}
.latest-news .newshead {
  float: none !important;
  margin: 0 !important;
}
.latest-news .picture {
  width: 100%;
  max-width: 100% !important;
  max-height: 240px !important;
  margin-bottom: 1.5rem;
  background-color: #ffffff;
}
.latest-news .picture a,
.latest-news .picture picture {
  display: inline-block;
  width: 100%;
  height: 240px !important;
}
.latest-news .innerpicture {
  width: 100%;
}
.latest-news .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.latest-news .innerpicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.latest-news .news {
  position: relative !important;
  overflow: hidden;
  height: 100% !important;
  margin: 0 !important;
  margin-bottom: 0 0 1rem !important;
  background-color: #f7f7f7 !important;
  border: none !important;
  border-radius: 8px;
}
.latest-news .innernews {
  border-radius: 0 !important;
}
.latest-news .newsinfo {
  padding: 0.5rem 2rem 4rem !important;
}
.latest-news .newstitle {
  font-size: 1.2rem;
  font-family: "halcom", sans-serif !important;
  font-weight: bold !important;
  line-height: 1.2 !important;
  margin: 0 0 1rem 0 !important;
  letter-spacing: 0;
}
.latest-news .newsintro {
  line-height: 1.6 !important;
  font-size: 1.1rem;
  font-size: 100%;
  color: #1c1c1c;
}
.latest-news .detail_category,
.latest-news .detail_tags {
  display: inline-flex;
  padding: 0.8rem;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  color: #ffffff;
}
.latest-news .detail_category {
  background: #00466e;
}
.latest-news .detail_tags {
  background: #047bc1;
}
.latest-news .detail_date {
  display: block;
  margin: 1em 0;
  font-family: "Lexend Deca", sans-serif;
  text-transform: uppercase;
}
.latest-news .detail_jfield_text,
.latest-news .detail_jfield_textarea {
  font-size: 0.9rem;
}
.latest-news .detail_tags .detail_data:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-bottom: 0.05rem;
  margin-right: 0.5rem;
  background-color: #1c1c1c;
  border-radius: 50%;
}
.latest-news__company, .latest-news__job {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.8rem;
}
.latest-news__job {
  font-weight: 500;
}
.latest-news .delimiter {
  display: none;
}
.latest-news .link {
  margin-top: 1rem !important;
  z-index: 1 !important;
  /*a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @extend .button;
  }*/
}
.latest-news .link a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  border-radius: 0;
  text-align: center;
  color: #ffffff;
}
.latest-news .link svg {
  height: 16px;
  margin-right: 0.5rem;
}
.latest-news--full .newslist ul.latestnews-items {
  flex-flow: row wrap !important;
}
@media (min-width: 576px) {
  .latest-news--full .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 960px) {
  .latest-news--full .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.latest-news--image-contain .innerpicture img {
  object-fit: contain;
  padding: 1rem;
}
.latest-news--cards .detail_tags, .latest-news--profile .detail_tags {
  display: inline-flex;
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 1;
  padding: 0.125rem 0;
  color: #1c1c1c;
  background-color: transparent;
  transition: 500ms ease-in-out;
}
.latest-news--cards .newsinfo {
  height: 100%;
  padding: 0.5rem 2rem 4rem !important;
}
.latest-news--cards-1 .link a {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  border-radius: 0;
  text-align: center;
  color: #ffffff;
}
.latest-news--cards-2 .latestnews-item > a:hover .link a {
  color: #72bf44 !important;
  border-bottom-color: #72bf44;
}
.latest-news--cards-2 .news {
  background-color: #ffffff !important;
}
.latest-news--cards-2 .newsinfo {
  padding-bottom: 6rem !important;
}
.latest-news--cards-2 .picture {
  max-height: 140px !important;
  margin: 0 !important;
}
.latest-news--cards-2 .picture a {
  height: 140px !important;
  padding: 2rem;
}
.latest-news--cards-2 .picture img {
  object-position: left;
}
.latest-news--cards-2 .link {
  width: auto !important;
}
.latest-news--cards-2 .link a {
  position: absolute;
  width: auto;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
  margin-bottom: 0;
  padding: 8px;
  background: transparent;
  border-bottom: 2px solid #1c1c1c;
  border-radius: 0;
  color: #1c1c1c !important;
}
.latest-news--cards-2 .link a:hover {
  background: transparent;
  color: #72bf44 !important;
  border-bottom-color: #72bf44;
}
.latest-news__event-summary {
  font-size: 1rem;
}
.latest-news--three-columns ul.latestnews-items, .latest-news--3-1 ul.latestnews-items {
  flex-flow: row !important;
  flex-wrap: wrap !important;
}
@media (max-width: 959px) {
  .latest-news--three-columns ul.latestnews-items, .latest-news--3-1 ul.latestnews-items {
    flex-direction: column !important;
  }
}
@media (min-width: 960px) {
  .latest-news--three-columns .latestnews-item, .latest-news--3-1 .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
@media (max-width: 959px) {
  .latest-news--three-columns .latestnews-item, .latest-news--3-1 .latestnews-item {
    flex: 1 1 0px !important;
    min-width: 0 !important;
    max-width: 100% !important;
  }
}
.latest-news--four-columns ul.latestnews-items, .latest-news--4-3-2-1 ul.latestnews-items {
  flex-flow: row !important;
  flex-wrap: wrap !important;
}
@media (max-width: 575px) {
  .latest-news--four-columns ul.latestnews-items, .latest-news--4-3-2-1 ul.latestnews-items {
    flex-direction: column !important;
  }
}
.latest-news--four-columns .latestnews-item, .latest-news--4-3-2-1 .latestnews-item {
  padding: 0 1rem 2rem !important;
}
@media (max-width: 575px) {
  .latest-news--four-columns .latestnews-item, .latest-news--4-3-2-1 .latestnews-item {
    flex: 1 1 0px !important;
    min-width: 0 !important;
    max-width: 100% !important;
  }
}
@media (min-width: 576px) {
  .latest-news--four-columns .latestnews-item, .latest-news--4-3-2-1 .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 960px) {
  .latest-news--four-columns .latestnews-item, .latest-news--4-3-2-1 .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
@media (min-width: 1280px) {
  .latest-news--four-columns .latestnews-item, .latest-news--4-3-2-1 .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0rem !important;
  }
}
.latest-news--4-2-1 ul.latestnews-items {
  flex-flow: row !important;
  flex-wrap: wrap !important;
}
@media (max-width: 575px) {
  .latest-news--4-2-1 ul.latestnews-items {
    flex-direction: column !important;
  }
}
.latest-news--4-2-1 .latestnews-item {
  padding: 0 1rem 2rem !important;
}
@media (max-width: 575px) {
  .latest-news--4-2-1 .latestnews-item {
    flex: 1 1 0px !important;
    min-width: 0 !important;
    max-width: 100% !important;
  }
}
@media (min-width: 576px) {
  .latest-news--4-2-1 .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 1024px) {
  .latest-news--4-2-1 .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0rem !important;
  }
}
.latest-news--title-large .newstitle {
  font-size: 1.6rem;
}
.latest-news--profile .picture {
  max-height: 320px !important;
}
.latest-news--profile .picture a,
.latest-news--profile .picture picture {
  height: 320px !important;
}
.latest-news--border .news {
  border: 2px solid #f7f7f7 !important;
}
.latest-news--image-height .innerpicture a,
.latest-news--image-height .innerpicture picture {
  display: flex !important;
  align-items: center;
}
.latest-news--image-height .innerpicture img {
  max-height: 125px !important;
}
.latest-news--height .latestnews-items {
  display: flex;
}
.latest-news--height .latestnews-item {
  flex: 1;
}
.latest-news .event-coming-soon {
  display: none;
}
.latest-news .event-coming-soon .detail_label {
  font-size: 1rem;
  font-weight: bold;
}
.latest-news .event-coming-soon .detail_data {
  display: none;
}
.latest-news--slider ul.latestnews-items,
.latest-news--slider .tns-ovh {
  overflow: visible !important;
}
.latest-news--slider-three ul.latestnews-items {
  flex-flow: row !important;
  width: 1600% !important;
}
@media (min-width: 768px) {
  .latest-news--slider-three ul.latestnews-items {
    width: 800% !important;
  }
}
@media (min-width: 960px) {
  .latest-news--slider-three ul.latestnews-items {
    width: 533.333% !important;
  }
}
.latest-news .tns-nav {
  opacity: 0;
  transition: 500ms ease-in-out;
}
.latest-news .tns-nav button {
  background: transparent;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  border: 2px solid #5cb8ef;
  border-radius: 50px;
  margin: 4px;
}
.latest-news .tns-nav > .tns-nav-active {
  background: transparent;
  border: 2px solid #00466e;
}

/* News page
================================================== */
.latest-news .row {
  padding: 6em 0;
}
.latest-news__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.latest-news__title h2 {
  margin: 0;
}

#news-article {
  background-color: #cacaca;
}

.news-item-article {
  padding-bottom: 3rem;
}
.news-item-article .main-content {
  flex-direction: column;
  padding: 0;
}
.news-item-article .com-content-image,
.news-item-article .com-content-article {
  max-width: 960px;
  width: 100%;
  margin: auto;
}
.news-item-article .com-content-image {
  margin-bottom: 0;
}
.news-item-article .com-content-image figure {
  position: relative;
  margin: 0;
  background-color: #ffffff;
}
.news-item-article .com-content-image img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
.news-item-article .com-content-article {
  background-color: #ffffff;
  padding: 3rem;
}
.news-item-article h1 {
  margin-top: 2rem;
  color: #047bc1;
  font-size: 2.5rem;
  font-weight: 700;
}
.news-item-article .article-info.muted {
  display: block;
  color: #1c1c1c;
  margin-bottom: 2em;
}
.news-item-article .share-buttons {
  margin-top: 4rem;
  text-align: center;
}
.news-item-article .share-buttons .button {
  margin-top: 1rem;
}
.news-item-article .share-buttons .st-btn {
  background-color: #047bc1 !important;
  border-radius: 24px !important;
  margin: 1rem;
}
.news-item-article--profile .com-content-image img {
  object-fit: contain;
}

.article-info.muted {
  display: none;
}

/* Footer
================================================== */
#sponsors-section {
  padding: 0;
  background-color: #ffffff;
}

.sponsors {
  display: flex;
  align-items: stretch;
}
.sponsors .n2-section-smartslider .n2-ss-section-main-content,
.sponsors .n2-section-smartslider .n2-ss-layer,
.sponsors .n2-section-smartslider .n2-ss-layer-col {
  padding: 0 !important;
}
.sponsors .n2-section-smartslider .n2-ss-margin {
  margin: 0 !important;
}
.sponsors .n2-section-smartslider img {
  width: 100% !important;
  max-width: 180px !important;
  max-height: 80px !important;
}
.sponsors .btn-logo--slider {
  box-shadow: none !important;
}
.sponsors__left .wf-columns {
  flex-direction: column-reverse;
  gap: 0;
  height: 100%;
}
@media (min-width: 1024px) {
  .sponsors__left .wf-columns {
    flex-direction: row;
  }
}
.sponsors__left-image img {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 400px;
}
@media (min-width: 960px) {
  .sponsors__left-image img {
    max-height: 100%;
  }
}
.sponsors__left-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 2rem;
  background-color: #00466e;
}
.sponsors__left-content * {
  color: #ffffff;
}
.sponsors__left-content .button {
  margin: 0;
}
.sponsors__right {
  padding: 3rem 0;
}
.sponsors__right p {
  text-transform: uppercase;
  font-size: 0.8rem;
}
.sponsors__right p strong {
  font-weight: 400;
}

footer * {
  color: #ffffff;
}
@media (max-width: 575px) {
  footer * {
    text-align: center;
  }
}
footer a:visited,
footer .nav a:visited {
  color: #ffffff;
}
footer a:hover,
footer .nav a:hover {
  color: #72bf44;
}
footer h3 {
  margin: 0 0 1.5rem;
  font-size: 1.4rem;
  font-family: "Lexend Deca", sans-serif;
  text-transform: capitalize;
  letter-spacing: normal;
  font-weight: bold;
}
footer .nav {
  display: block;
}
footer .nav li {
  display: block;
  width: 100%;
  margin-bottom: 0.6em;
  text-align: left;
}
@media (max-width: 575px) {
  footer .nav li {
    text-align: center;
  }
}
footer .nav a {
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #ffffff;
  padding: 0;
  text-transform: none;
  font-weight: normal;
}
footer .social {
  margin-bottom: 2em;
}
footer .social li {
  height: 48px;
}
footer .social a * {
  fill: #ffffff;
}
footer .social a:hover * {
  fill: #72bf44;
}

.footer__border {
  width: 100%;
  height: 10px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#168ab9+1,96d46d+100 */
  background: #168ab9; /* Old browsers */
  background: -moz-linear-gradient(left, #168ab9 1%, #96d46d 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #168ab9 1%, #96d46d 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #168ab9 1%, #96d46d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#168ab9", endColorstr="#96d46d",GradientType=1 ); /* IE6-9 */
}
.footer__top {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  padding: 4em;
  background-color: #1c1c1c;
}
@media (min-width: 960px) {
  .footer__top {
    padding: 6em 0;
  }
}
@media (max-width: 575px) {
  .footer__top .columns {
    margin-bottom: 3em;
  }
}
.footer__top--2 {
  margin-top: 3rem !important;
}
.footer__logo {
  margin-bottom: 1rem;
}
.footer__logo img {
  width: 300px;
}
.footer__left ul {
  margin: 0;
  list-style: none;
}
.footer__left span {
  margin-left: 2em;
  font-weight: bold;
}
@media (max-width: 959px) {
  .footer__left span {
    display: block;
    margin: 0;
  }
}
.footer__copyright {
  font-size: 0.7em;
  margin-bottom: 1rem;
}
.footer__bottom {
  margin-top: 0;
  padding: 16px 0;
  background-color: #080808;
  text-align: center;
}
.footer__bottom .row {
  padding: 0;
  align-items: center;
}
.footer__bottom-left img {
  width: 200px;
}
@media (min-width: 576px) {
  .footer__bottom-right p {
    justify-content: flex-end;
  }
}
@media (max-width: 479px) {
  .footer__bottom-right p {
    flex-direction: column;
  }
}
.footer__bottom-right a {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
}
.footer__bottom-right img {
  width: 220px;
}
@media (min-width: 480px) {
  .footer__bottom-right img {
    width: 180px;
    margin-left: 0.6rem;
  }
}
.footer__bottom p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #ffffff;
  font-size: 0.8em;
}
@media (max-width: 575px) {
  .footer__bottom p {
    justify-content: center;
  }
}
.footer__bottom img {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  transition: 500ms ease-in-out;
}
.footer__bottom img:hover {
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* Aninmations
================================================== */
/* Hover bob effect */
.hvr-bob {
  transition-duration: 0.5s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
}
.hvr-bob:hover {
  transform: translateY(-6px);
  animation-name: hover;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}