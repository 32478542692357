h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  display: inline-flex;
  position: relative;
  margin: 0.5rem 0 2rem;
  font-family: $secondary-font;
  line-height: $base-heading-line-height;
  font-weight: $base-heading-font-weight;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1;
  &.title-style,
  &[itemprop='headline'] {
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 40%;
      height: 10px;
      background: rgb(4, 123, 193);
      background: linear-gradient(
        90deg,
        rgba(4, 123, 193, 1) 0%,
        rgba(144, 224, 90, 1) 100%
      );
    }
  }
  &.title-style-lower {
    font-size: 2rem;
    text-transform: none;
    letter-spacing: 0;
  }
}

h1,
.h1 {
  font-size: $base-h1-font-size-mobile;
  &--mobile {
    font-size: $base-h1-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h1-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h1-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h1-font-size;
  }
}
h2,
.h2 {
  font-size: $base-h2-font-size-mobile;
  &--mobile {
    font-size: $base-h2-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h2-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h2-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h2-font-size;
  }
}
h3,
.h3 {
  font-size: $base-h3-font-size-mobile;
  &--mobile {
    font-size: $base-h3-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h3-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h3-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h3-font-size;
  }
}
h4,
.h4 {
  font-size: $base-h4-font-size-mobile;
  &--mobile {
    font-size: $base-h4-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h4-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h4-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h4-font-size;
  }
}
h5,
.h5 {
  font-size: $base-h5-font-size-mobile;
  &--mobile {
    font-size: $base-h5-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h5-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h5-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h5-font-size;
  }
}
h6,
.h6 {
  font-size: $base-h6-font-size-mobile;
  &--mobile {
    font-size: $base-h6-font-size-mobile;
  }
  &--tablet {
    font-size: $base-h6-font-size-tablet;
  }
  @include bp(medium) {
    font-size: $base-h6-font-size-tablet;
  }
  @include bp(large) {
    font-size: $base-h6-font-size;
  }
}

.subheading {
  font-family: $primary-font;
  text-transform: uppercase;
  color: $primary-colour;
  font-weight: bold;
  letter-spacing: 3px;
  &--2 {
    color: $secondary-colour;
  }
  &--3 {
    color: $tertiary-colour;
  }
}
