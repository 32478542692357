/* Aninmations
================================================== */

/* Hover bob effect */


// Default options
$defaultDuration: .3s;



// As is often the case, some devices/browsers need additional code to get CSS to work
// in the most desired way. These mixins are used to quickly drop in hacks for each element
@mixin hideTapHighlightColor() {
	//Prevent highlight colour when element is tapped
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

@mixin hardwareAccel() {
	//Improve performance on mobile/tablet devices
	transform: translateZ(0);
}

@mixin improveAntiAlias() {
	//Improve aliasing on mobile/tablet devices
  //box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}


.hvr-bob {
	//display: inline-block;
	transition-duration: .5s;
	transition-property: transform;

	@include hideTapHighlightColor();
	@include hardwareAccel();
	@include improveAntiAlias();

	&:hover {
		transform: translateY(-6px);
		animation-name: hover;
		animation-duration: 1.5s;
		animation-delay: $defaultDuration;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-direction: alternate;
	}
}