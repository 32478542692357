// 1. File Paths
// Usage - e.g. url('#{$image-directory}...')
// --------------
$public-path: '../';
$image-directory: '../images/';
$font-directory: '../fonts/';

// 2. Width
// --------------
$global-width: 1440px;

// 3. Fonts
// --------------
@import url('https://use.typekit.net/ahj2jxl.css');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Smooch+Sans:wght@100..900&display=swap');
$primary-font: 'Lexend Deca', sans-serif;
$secondary-font: 'halcom', sans-serif;
$tertiary-font: 'Smooch Sans', sans-serif;
$quartinary-font: 'europa', sans-serif;
//$secondary-font: "Work Sans", sans-serif;

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #047bc1; // Primary Blue
$primary-highlight: #5cb8ef; // Primary Blue highlight
$secondary-colour: #00466e;
$tertiary-colour: #72bf44; // Green

/// @type Map
$foundation-palette: (
  primary: $primary-colour,
  secondary: $secondary-colour,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #f2f6fa !default;
$medium-grey: #cacaca !default;
$dark-grey: #5e717c !default;
$black: #1c1c1c !default;
$darker-black: #080808 !default;
$white: #ffffff !default;
$off-white: #f7f7f7 !default;
$dark-blue: #00466e !default;
$light-blue: #f2fbfc !default;

$primary-colour-light: lighten($primary-colour, 8%);
$primary-colour-light: lighten($primary-colour, 8%);
$secondary-colour-light: lighten($secondary-colour, 8%);
$secondary-colour-dark: darken($secondary-colour, 8%);
$tertiary-colour-light: lighten($tertiary-colour, 8%);
$tertiary-colour-dark: darken($tertiary-colour, 8%);

.primary-colour {
  color: $primary-colour;
}
.highlight-colour {
  color: $primary-highlight;
}
.secondary-colour {
  color: $secondary-colour;
}
.tertiary-colour {
  color: $tertiary-colour;
}

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 100% !default;
$base-line-height: 1.5 !default;
$base-font-color: $black !default;
$base-font-weight: 300 !default;
$base-background-color: $white !default;

// 6. Headings
// --------------
$base-heading-font-family: $primary-font !default;
$base-heading-line-height: 1.2 !default;
$base-heading-font-weight: 700 !default;

$base-heading-large: 3rem !default;

$base-h1-font-size: 3.2rem !default;
$base-h2-font-size: 3rem !default;
$base-h3-font-size: 2.8rem !default;
$base-h4-font-size: 2.6rem !default;
$base-h5-font-size: 2.4rem !default;
$base-h6-font-size: 2.2rem !default;

$base-h1-font-size-tablet: 2.8rem !default;
$base-h2-font-size-tablet: 2.6rem !default;
$base-h3-font-size-tablet: 2.4rem !default;
$base-h4-font-size-tablet: 2.2rem !default;
$base-h5-font-size-tablet: 2rem !default;
$base-h6-font-size-tablet: 1.8rem !default;

$base-h1-font-size-mobile: 2.4rem !default;
$base-h2-font-size-mobile: 2.2rem !default;
$base-h3-font-size-mobile: 2rem !default;
$base-h4-font-size-mobile: 1.8rem !default;
$base-h5-font-size-mobile: 1.6rem !default;
$base-h6-font-size-mobile: 1.4rem !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
$breakpoints: (
  small: 0,
  smedium: 480px,
  medium: 576px,
  mlarge: 768px,
  large: 960px,
  xlarge: 1024px,
  xxlarge: 1280px,
  xxxlarge: $global-width,
  xxxxlarge: 1800px,
);
$breakpoint-classes: (
  small smedium medium mlarge large xlarge xxlarge xxxlarge xxxxlarge
);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: 'columns';
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;

// 9. Global
// --------------
$global-lineheight: 1.5 !default;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transition
// --------------
$global-transition: 500ms ease-in-out;
